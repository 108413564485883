import React from "react";
import "./TotalAssessment.css";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Box from '@mui/material/Box';
import ykbutBg from '..//..//assets//background.png';
import AddIcon from '@mui/icons-material/Add';

import TabelAssessment from "../TabelAssessment";

const TotalAssessment = () => {
  const history = useHistory();

  const handleClick = () => {
    
    history.push("/HomePage");
  };

  return (
    <div>
      <Box component="main" sx={{ 
        flexGrow: 1, p: 3,
        backgroundImage: `url(${ykbutBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center', 
        }} 
        className='img-ykbut'>
    <div className="text-assest">
      <div className="back-icon-assessment">
        <ArrowBackIcon onClick={handleClick} />
      </div>
    </div>
    <div className="wrapper-8">
        <h1 className="judul-assessment">Assessment</h1>
        <div className="tabel-assesst">
          <TabelAssessment />
        </div>
        <NavLink link to="/AssessmentForm">
          <button type="button" className="btn-tabel-assesst">
            New Assessment
          </button>
        </NavLink>
    </div>
    </Box>
    </div>
  );
};

export default TotalAssessment;

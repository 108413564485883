import React, { useEffect, useState } from "react";
import axios from "axios";
import peduli_logo from "../../assets/peduliinsani_logo.png";
import "./AssessmentPage.css";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const AssessmentPage4 = () => {
  const history = useHistory();
  const [assessmentData, setAssessmentData] = useState([]);
  const [assData, setAssData] = useState([]);
  const [idin, setIdin] = useState(-1);
  const [innovation, setInnovation] = useState('');
  const [innovation_feedback, setInnovation_feedback] = useState('');
  const [sin, setSin] = useState(-1);
  const userId = localStorage.getItem('user_id');
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://odoo-toca.ykbut.co.id/get_assessment_def`
        );
        const data = response.data;

        setAssessmentData(data.assessment_data4);
        setAssData(data.ass_data4);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  const handleOptionChange = (event) => {
    const innovationValue = event.target.value;
    setInnovation(innovationValue);

    //get array selection
    const selectedValue = event.target.value;
    const selectedIndex = assData.findIndex((item) => item === selectedValue);
    // console.log(`Selected index: ${selectedIndex}`);
    let idin;

    switch (selectedIndex) {
      case 0:
        idin = 26;
        break;
      case 1:
        idin = 27;
        break;
      case 2:
        idin = 28;
        break;
      case 3:
        idin = 29;
        break;
      case 4:
        idin = 30;
        break;
      default:
        idin = -1;
    }
    setIdin(idin);

    let sin;

    switch (idin) {
      case 26:
        sin = 1;
        break;
      case 27:
        sin = 2;
        break;
      case 28:
        sin = 3;
        break;
      case 29:
        sin = 4;
        break;
      case 30:
        sin = 5;
        break;
      default:
        sin = -1;
    }
    setSin(sin);
  };

  const handleFeedbackChange = (event) => {
    const feedbackValue = event.target.value;
    setInnovation_feedback(feedbackValue);
  };

  const handleNextButton = () => {
    if (!innovation) {
      Swal.fire({
        icon: "warning",
        title: "Pilihan harus dipilih",
        text: "Pastikan Anda telah memilih salah satu opsi sebelum melanjutkan",
        confirmButtonColor: "#59c1bd"
      });
    } else {
      localStorage.setItem('idin', idin);
      localStorage.setItem('innovation_feedback', innovation_feedback);
      localStorage.setItem('sin', sin);
  
      if (sin <= 3 && innovation_feedback.trim().length < 15) {
        Swal.fire({
          icon: "warning",
          title: "Feedback harus diisi",
          text: "Pastikan anda sudah mengisi feedback dengan minimal 15 karakter untuk score 1-3",
          confirmButtonColor: "#59c1bd"
        });
      } else {
        history.push('/AssessmentTools5');
      }
    }
  };

  const handleBacktButton = () => {

    history.push('/AssessmentTools3');
  };
  return (
    <div className="card-quest1">
      <div className={["logo-peduli-asst"]}>
        <img className="logo-pi" src={peduli_logo} />
      </div>

      <div className="">
        <div className={["card-assessment"]}>
          {assessmentData.map((item, idx) => (
            <div className="div">
              <p key={idx} className={["txt-values"]}>{item.core}</p>
              <p key={idx} className={["txt-definisi"]}>{item.description}</p>
            </div>
          ))}
        </div>

        {assData.map((item, idx) => (
            <div key={idx} className="card-quest">
              <div className='option'>
                <input 
                id={`option-${idx}`} 
                type="radio" 
                name="field" 
                value={item}
                checked={innovation === item}
                onChange={handleOptionChange} 
                />
                <label key={idx} htmlFor={`option-${idx}`} className={["font-option"]}>{item}</label>
              </div>
            </div>
          ))}
        
          <div className="feedback">
            <Typography className='header-feedback'> Employees Feedback</Typography>
            <textarea 
            className="komentar"
            placeholder="Minimal 15 Karakter"
            value={innovation_feedback}
            onChange={handleFeedbackChange}
            ></textarea>
      </div>
        <div className="btn-page1">
          <button type="button" className="btn-feedback-form" onClick={handleBacktButton}>
            Back
          </button>
          <button type="button" className="btn-feedback-form" onClick={handleNextButton}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default AssessmentPage4;

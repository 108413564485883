import React, { useEffect, useState } from "react";
import axios from "axios";
import peduli_logo from "../../assets/peduliinsani_logo.png";
import "./AssessmentPage.css";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const AssessmentPage5 = () => {
  const history = useHistory();
  const [assessmentData, setAssessmentData] = useState([]);
  const [assData, setAssData] = useState([]);
  const [idsa, setIdsa] = useState(-1);
  const [safety, setSafety] = useState('');
  const [safety_feedback, setSafety_feedback] = useState('');
  const [ssa, setSsa] = useState(-1);
  const userId = localStorage.getItem('user_id');
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://odoo-toca.ykbut.co.id/get_assessment_def`
        );
        const data = response.data;

        setAssessmentData(data.assessment_data5);
        setAssData(data.ass_data5);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  const handleOptionChange = (event) => {
    const safetyValue = event.target.value;
    setSafety(safetyValue);

    //get array selection
    const selectedValue = event.target.value;
    const selectedIndex = assData.findIndex((item) => item === selectedValue);
    // console.log(`Selected index: ${selectedIndex}`);
    let idsa;

    switch (selectedIndex) {
      case 0:
        idsa = 11;
        break;
      case 1:
        idsa = 12;
        break;
      case 2:
        idsa = 13;
        break;
      case 3:
        idsa = 14;
        break;
      case 4:
        idsa = 15;
        break;
      default:
        idsa = -1;
    }
    setIdsa(idsa);

    let ssa;

    switch (idsa) {
      case 11:
        ssa = 1;
        break;
      case 12:
        ssa = 2;
        break;
      case 13:
        ssa = 3;
        break;
      case 14:
        ssa = 4;
        break;
      case 15:
        ssa = 5;
        break;
      default:
        ssa = -1;
    }
    setSsa(ssa);
  };

  const handleFeedbackChange = (event) => {
    const safetyValue = event.target.value;
    setSafety_feedback(safetyValue);
  };

  const handleNextButton = () => {
    if (!safety) {
      Swal.fire({
        icon: "warning",
        title: "Pilihan harus dipilih",
        text: "Pastikan Anda telah memilih salah satu opsi sebelum melanjutkan",
        confirmButtonColor: "#59c1bd"
      });
    } else {
      localStorage.setItem('idsa', idsa);
      localStorage.setItem('safety_feedback', safety_feedback);
      localStorage.setItem('ssa', ssa);
  
      if (ssa <= 3 && safety_feedback.trim().length < 15) {
        Swal.fire({
          icon: "warning",
          title: "Feedback harus diisi",
          text: "Pastikan anda sudah mengisi feedback dengan minimal 15 karakter untuk score 1-3",
          confirmButtonColor: "#59c1bd"
        });
      } else {
        history.push('/AssessmentTools6');
      }
    }
  };
  
  const handleBacktButton = () => {

    history.push('/AssessmentTools4');
  };
  return (
    <div className="card-quest1">
      <div className={["logo-peduli-asst"]}>
        <img className="logo-pi" src={peduli_logo} />
      </div>

      <div className="">
        <div className={["card-assessment"]}>
          {assessmentData.map((item, idx) => (
            <div className="div">
              <p key={idx} className={["txt-values"]}>{item.core}</p>
              <p key={idx} className={["txt-definisi"]}>{item.description}</p>
            </div>
          ))}
        </div>

        {assData.map((item, idx) => (
            <div key={idx} className="card-quest">
              <div className='option'>
                <input 
                id={`option-${idx}`} 
                type="radio" 
                name="field" 
                value={item}
                checked={safety === item}
                onChange={handleOptionChange} 
                />
                <label key={idx} htmlFor={`option-${idx}`} className={["font-option"]}>{item}</label>
              </div>
            </div>
          ))}
        
          <div className="feedback">
            <Typography className='header-feedback'> Employees Feedback</Typography>
            <textarea 
            className="komentar"
            placeholder="Minimal 15 Karakter"
            value={safety_feedback}
            onChange={handleFeedbackChange}
            ></textarea>
      </div>
        <div className="btn-page1">
          <button type="button" className="btn-feedback-form" onClick={handleBacktButton}>
            Back
          </button>
          <button type="button" className="btn-feedback-form" onClick={handleNextButton}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default AssessmentPage5;

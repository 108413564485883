// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    min-width: 400px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin-left: 320px;
    margin-top: 20px !important;
}

.content-table th,
.content-table td {
    padding: 12px 15px;
}

.content-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
    background-color: #A5CCD9;
}

.content-table tbody tr:last-of-type {
    border-bottom: 2px solid #176B87;
}

.content-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

.table th{
    padding: 1px 2px !important;
    color: #fff;
    margin-left: 50% !important;
}
.table td {
    padding: 4px 9px !important;
    color: black;
    /* background-color: ; */
}

.table tbody tr {
    border-bottom: 1px solid #fff;
} 

.table tbody tr:nth-of-type(even) {
    background-color:  1d91b7 !important;
}

.table tbody tr:last-of-type {
    border-bottom: 1px solid black !important;
}

.table tbody tr.active-row {
    font-weight: bold;
    color: #1fdab5;
}

@media (max-width: 576px) {
    .table {
      width: 100%;
    }
  
    .table th, .table td {
      padding: 8px; 
    }
  }
`, "",{"version":3,"sources":["webpack://./src/components/TabelAssessor/TabelAssessor.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,0BAA0B;IAC1B,gBAAgB;IAChB,wCAAwC;IACxC,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;;IAEI,kBAAkB;AACtB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,2BAA2B;IAC3B,WAAW;IACX,2BAA2B;AAC/B;AACA;IACI,2BAA2B;IAC3B,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI;MACE,WAAW;IACb;;IAEA;MACE,YAAY;IACd;EACF","sourcesContent":[".content-table {\n    border-collapse: collapse;\n    margin: 25px 0;\n    font-size: 0.9em;\n    min-width: 400px;\n    border-radius: 5px 5px 0 0;\n    overflow: hidden;\n    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);\n    margin-left: 320px;\n    margin-top: 20px !important;\n}\n\n.content-table th,\n.content-table td {\n    padding: 12px 15px;\n}\n\n.content-table tbody tr {\n    border-bottom: 1px solid #dddddd;\n}\n\n.content-table tbody tr:nth-of-type(even) {\n    background-color: #A5CCD9;\n}\n\n.content-table tbody tr:last-of-type {\n    border-bottom: 2px solid #176B87;\n}\n\n.content-table tbody tr.active-row {\n    font-weight: bold;\n    color: #009879;\n}\n\n.table th{\n    padding: 1px 2px !important;\n    color: #fff;\n    margin-left: 50% !important;\n}\n.table td {\n    padding: 4px 9px !important;\n    color: black;\n    /* background-color: ; */\n}\n\n.table tbody tr {\n    border-bottom: 1px solid #fff;\n} \n\n.table tbody tr:nth-of-type(even) {\n    background-color:  1d91b7 !important;\n}\n\n.table tbody tr:last-of-type {\n    border-bottom: 1px solid black !important;\n}\n\n.table tbody tr.active-row {\n    font-weight: bold;\n    color: #1fdab5;\n}\n\n@media (max-width: 576px) {\n    .table {\n      width: 100%;\n    }\n  \n    .table th, .table td {\n      padding: 8px; \n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

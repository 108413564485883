// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Gaya asli untuk layar yang lebih besar */
  .wrapper-6 {
      width: 400px;
      height: 80%;
      margin: 80px auto;
      padding: 40px 30px 30px 30px;
      background-color: #fff;
      border-radius: 15px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      margin-top: 50px;
      position: relative;
      padding: 10px;
      overflow: auto !important;
  }
  
  /* Gaya CSS untuk tampilan mobile */
  @media (max-width: 767px) {
    .wrapper-6 {
      width: 98%;
      margin-top: 20%;
      overflow: auto;
    }
  }
  
  /* Gaya CSS untuk tampilan iPad */
  @media (min-width: 768px) and (max-width: 1024px) {
    .wrapper-6 {
      width: 50%;
      overflow: auto;
      margin-top: 15%;
    }
  }
  
  /* Gaya tambahan untuk elemen-elemen di dalam wrapper */
  .back-icon-assessor {
    float: left;
    margin-top: 2%;
    padding-left: 4%;
    font-size: 900;
    color: #fff;
  }
  
  .text-assessor {
    margin-top: 2%;
    display: flex;
    justify-content: center;
  }
  
  .judul-assessor {
    font-family: 'Poppins';
    font-size: 25px;
    font-weight: bold !important;
    margin-bottom: 6%;
    color: black;
  }
  
  .tabel-assessor {
    margin-left: 6%;
    margin-right: 6%;
  }
  
`, "",{"version":3,"sources":["webpack://./src/components/TotalAssessor/TotalAssesor.css"],"names":[],"mappings":"AAAA,2CAA2C;EACzC;MACI,YAAY;MACZ,WAAW;MACX,iBAAiB;MACjB,4BAA4B;MAC5B,sBAAsB;MACtB,mBAAmB;MACnB,wCAAwC;MACxC,gBAAgB;MAChB,kBAAkB;MAClB,aAAa;MACb,yBAAyB;EAC7B;;EAEA,mCAAmC;EACnC;IACE;MACE,UAAU;MACV,eAAe;MACf,cAAc;IAChB;EACF;;EAEA,iCAAiC;EACjC;IACE;MACE,UAAU;MACV,cAAc;MACd,eAAe;IACjB;EACF;;EAEA,uDAAuD;EACvD;IACE,WAAW;IACX,cAAc;IACd,gBAAgB;IAChB,cAAc;IACd,WAAW;EACb;;EAEA;IACE,cAAc;IACd,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,sBAAsB;IACtB,eAAe;IACf,4BAA4B;IAC5B,iBAAiB;IACjB,YAAY;EACd;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB","sourcesContent":["/* Gaya asli untuk layar yang lebih besar */\n  .wrapper-6 {\n      width: 400px;\n      height: 80%;\n      margin: 80px auto;\n      padding: 40px 30px 30px 30px;\n      background-color: #fff;\n      border-radius: 15px;\n      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n      margin-top: 50px;\n      position: relative;\n      padding: 10px;\n      overflow: auto !important;\n  }\n  \n  /* Gaya CSS untuk tampilan mobile */\n  @media (max-width: 767px) {\n    .wrapper-6 {\n      width: 98%;\n      margin-top: 20%;\n      overflow: auto;\n    }\n  }\n  \n  /* Gaya CSS untuk tampilan iPad */\n  @media (min-width: 768px) and (max-width: 1024px) {\n    .wrapper-6 {\n      width: 50%;\n      overflow: auto;\n      margin-top: 15%;\n    }\n  }\n  \n  /* Gaya tambahan untuk elemen-elemen di dalam wrapper */\n  .back-icon-assessor {\n    float: left;\n    margin-top: 2%;\n    padding-left: 4%;\n    font-size: 900;\n    color: #fff;\n  }\n  \n  .text-assessor {\n    margin-top: 2%;\n    display: flex;\n    justify-content: center;\n  }\n  \n  .judul-assessor {\n    font-family: 'Poppins';\n    font-size: 25px;\n    font-weight: bold !important;\n    margin-bottom: 6%;\n    color: black;\n  }\n  \n  .tabel-assessor {\n    margin-left: 6%;\n    margin-right: 6%;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Skeleton, Tooltip, Typography } from "@mui/material";
import { idID } from "@mui/material/locale";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Development from "../../assets/development.png";


const Magazine = ({ dataMagazine }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (dataMagazine !== undefined || dataMagazine.length !== 0) {
      setLoading(false);
    }
  }, [dataMagazine]);

  // console.log("dataDummy", dataMagazine);
  
  // console.log("penanda", dataMagazine);

  // const Magazine = ({ dataMagazine }) => {
  return (
    <>
      <div className="w-full max-w-lg mx-auto">
      {loading ? (
        // Menampilkan Skeleton jika loading
        <div>
          {[...Array(4)].map((_, index) => (
            <Skeleton key={index} animation="wave" height={"80px"} />
          ))}
        </div>
      ) : !dataMagazine || dataMagazine.length == 0 ? (
        <div className="justify-center text-center">
          <img
            className="w-[300px] h-[180px] mx-auto"
            src={Development}
            alt="Development"
          />
          <Typography
            sx={{
              fontWeight: "bold",
              marginTop: "10px",
              fontStyle: "italic",
            }}
          >
            Dalam Tahapan Pengembangan
          </Typography>
        </div>
      ) : (
        dataMagazine.map((data) => (
          <NavLink key={data.id} to={`/magazine/${data.id}`}>
            <div className="bg-[#F6F6F6] flex gap-4 p-4 mb-4 rounded-lg shadow-md hover:shadow-lg cursor-pointer transition-shadow duration-300 h-full">
              <img
                className="w-[120px] h-full rounded-md object-cover"
                src={`data:image/jpeg;base64,${data.image}`}
                alt={data.name}
              />
              <Tooltip title={data.name}>
              <div className="flex-1 h-full flex-col justify-between">
                <h3 className="text-md font-semibold text-gray-800">
                  {data.name}
                </h3>
                <p className="text-xs text-gray-600 line-clamp-4 mt-[10px] text-justify">
                  {data.description}
                </p>
              </div>
              </Tooltip>
            </div>
          </NavLink>
        ))
      )}
    </div>
    </>
  );
};

export default Magazine;

// grid grid-cols-3 gap-4

import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";

const Mandatory = () => {
  const [assessmentData, setAssessmentData] = useState([]);
  const userId = localStorage.getItem('user_id');
  const token = localStorage.getItem('token'); // Retrieve the access token

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the headers
            'Content-Type': 'application/json'
          }
        });
        setAssessmentData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId, token]); // Include token in the dependency array

  return (
    <>
      <div className="wrapper-tabel">
        <Table responsive="sm">
          <thead>
            <tr>
              <th>Assessment</th>
              <th>Name</th>
              <th>NRP</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {assessmentData.map((row) => (
              <tr key={row.Assessment}>
                <td> {row.name} </td>
                <td> {row.assessment} </td>
                <td> {row.nrp} </td>
                <td> {row.score} </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Mandatory;

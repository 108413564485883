import React, { useState, useRef } from 'react';
import './ProgressBar.css';

const App = () => {
  const [progress, setProgress] = useState(0);
  const progressBarRef = useRef(null);

  const handleDrag = (clientX) => {
    const rect = progressBarRef.current.getBoundingClientRect();
    const newProgress = ((clientX - rect.left) / rect.width) * 100;
    setProgress(Math.min(Math.max(newProgress, 0), 100));
  };

  const handleMouseDown = (event) => {
    handleDrag(event.clientX);

    const handleMouseMove = (event) => {
      handleDrag(event.clientX);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleTouchStart = (event) => {
    handleDrag(event.touches[0].clientX);

    const handleTouchMove = (event) => {
      handleDrag(event.touches[0].clientX);
    };

    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  };

  return (
    <div className="App-bar">
      <div
        className="progress-bar-container"
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        ref={progressBarRef}
      >
        <div className="progress-bar" style={{ width: `${progress}%` }} />
        <div className="marker" style={{ left: '0%', backgroundColor: '#C9A2C9' }}>A</div>
        <div className="marker" style={{ left: '48%', backgroundColor: '#975C8D' }}>B</div>
        <div className="marker" style={{ left: '93%', backgroundColor: '#E36BAE' }}>C</div>
      </div>
    </div>
  );
};

export default App;

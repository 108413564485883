// PrivateRoute.js
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../AuthContext/AuthContext'; // Sesuaikan path-nya

const PrivateRoute = ({ component: Component, dataDummy, ...rest }) => {
  const { isAuthenticated } = useAuth(); // Mengambil status autentikasi dari context
  const token = localStorage.getItem("accessToken"); // Memeriksa token di localStorage

  // Menentukan apakah pengguna terautentikasi atau tidak
  const isUserAuthenticated = isAuthenticated || token;

  return (
    <Route
      {...rest}
      render={(props) =>
        isUserAuthenticated ? (
          <Component {...props} dataDummy={dataDummy}/>
        ) : (
          <Redirect to="/" /> // Pastikan ini mengarahkan ke halaman login atau landing page
        )
      }
    />
  );
};

export default PrivateRoute;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comingchart{
  margin-top: 50%;
  text-align: center;
  font-size: 27px;
}

/* Responsive styling for tablets (like iPad) */
@media (min-width: 768px) and (max-width: 1024px) {
  .comingchart {
    font-size: 40px; /* Adjust font size for tablets */
  }
}

/* Responsive styling for mobile devices */
@media (max-width: 767px) {
  .comingchart {
    font-size: 21px; /* Adjust font size for mobile devices */
    margin-top: 40%; /* Adjust margin-top for mobile devices */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Chart2/Chart2.css"],"names":[],"mappings":"AAAA;EAEE,eAAe;EAKf,kBAAkB;EAClB,eAAe;AAJjB;;AAOA,+CAA+C;AAC/C;EACE;IACE,eAAe,EAAE,iCAAiC;EACpD;AACF;;AAEA,0CAA0C;AAC1C;EACE;IACE,eAAe,EAAE,wCAAwC;IACzD,eAAe,EAAE,yCAAyC;EAC5D;AACF","sourcesContent":[".comingchart{\n  text-align: center;\n  margin-top: 50%;\n  font-size: 25px;\n}\n\n.comingchart {\n  text-align: center;\n  font-size: 27px;\n}\n\n/* Responsive styling for tablets (like iPad) */\n@media (min-width: 768px) and (max-width: 1024px) {\n  .comingchart {\n    font-size: 40px; /* Adjust font size for tablets */\n  }\n}\n\n/* Responsive styling for mobile devices */\n@media (max-width: 767px) {\n  .comingchart {\n    font-size: 21px; /* Adjust font size for mobile devices */\n    margin-top: 40%; /* Adjust margin-top for mobile devices */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

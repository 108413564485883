import React, { useEffect, useState } from "react";
import axios from "axios";
import peduli_logo from "../../assets/peduliinsani_logo.png";
import "./AssessmentPage.css";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AssessmentPage6 = () => {
  const history = useHistory();
  const [assessmentData, setAssessmentData] = useState([]);
  const [assData, setAssData] = useState([]);
  const [idni, setIdni] = useState(-1);
  const [network, setNetwork] = useState('');
  const [network_feedback, setNetwork_feedback] = useState('');
  const [sni, setSni] = useState(-1);
  const userId = localStorage.getItem('user_id');
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://odoo-toca.ykbut.co.id/get_assessment_def`
        );
        const data = response.data;

        setAssessmentData(data.assessment_data6);
        setAssData(data.ass_data6);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  const handleOptionChange = (event) => {
    const networkValue = event.target.value;
    setNetwork(networkValue);

    //get array selection
    const selectedValue = event.target.value;
    const selectedIndex = assData.findIndex((item) => item === selectedValue);
    // console.log(`Selected index: ${selectedIndex}`);
    let idni;

    switch (selectedIndex) {
      case 0:
        idni = 6;
        break;
      case 1:
        idni = 7;
        break;
      case 2:
        idni = 8;
        break;
      case 3:
        idni = 9;
        break;
      case 4:
        idni = 10;
        break;
      default:
        idni = -1;
    }
    setIdni(idni);

    let sni;

    switch (idni) {
      case 6:
        sni = 1;
        break;
      case 7:
        sni = 2;
        break;
      case 8:
        sni = 3;
        break;
      case 9:
        sni = 4;
        break;
      case 10:
        sni = 5;
        break;
      default:
        sni = -1;
    }
    setSni(sni);
  };

  const handleFeedbackChange = (event) => {
    const networkValue = event.target.value;
    setNetwork_feedback(networkValue);
  };

  const handleNextButton = () => {

    if (!network) {
      Swal.fire({
        icon: "warning",
        title: "Pilihan harus dipilih",
        text: "Pastikan Anda telah memilih salah satu opsi sebelum melanjutkan",
        confirmButtonColor: "#59c1bd"
      });
    } else {
      localStorage.setItem('idni', idni);
      localStorage.setItem('network_feedback', network_feedback);
      localStorage.setItem('sni', sni);
  
      if (sni <= 3 && network_feedback.trim().length < 15) {
        Swal.fire({
          icon: "warning",
          title: "Feedback harus diisi",
          text: "Pastikan anda sudah mengisi feedback dengan minimal 15 karakter untuk score 1-3",
          confirmButtonColor: "#59c1bd"
        });
      } else {
        const employee_id = localStorage.getItem('id');
        const event_id = localStorage.getItem('event_id');
        const modul_id = localStorage.getItem('modul_id');
        const entitas_id = localStorage.getItem('entitas_id');
        const id = Number(employee_id);
        const e_id = Number(event_id);
        const m_id = Number(modul_id);
        const entitas_idi = Number(entitas_id);

        const penilai_id = localStorage.getItem('employee_id');
        const penilai_idi= Number(penilai_id);

        const p_id = localStorage.getItem('idp');
        const partner_f = localStorage.getItem('partner_feedback');
        const s_p = localStorage.getItem('sp'); 
        const p_idi = Number(p_id);
        const s_pi = Number(s_p);

        const du_id = localStorage.getItem('iddu');
        const diversity_f = localStorage.getItem('diversity_feedback'); 
        const s_du = localStorage.getItem('sdu');
        const du_idi = Number(du_id);
        const s_dui = Number(s_du);  

        const li_id = localStorage.getItem('idli');
        const leader_f = localStorage.getItem('leader_feedback'); 
        const s_li = localStorage.getItem('sli'); 
        const li_idi = Number(li_id);
        const s_lii = Number(s_li); 

        const in_id = localStorage.getItem('idin');
        const innovation_f = localStorage.getItem('innovation_feedback'); 
        const s_in = localStorage.getItem('sin'); 
        const in_idi = Number(in_id);
        const s_ini = Number(s_in); 

        const sa_id = localStorage.getItem('idsa');
        const safety_f = localStorage.getItem('safety_feedback'); 
        const s_sa = localStorage.getItem('ssa');
        const sa_idi = Number(sa_id);
        const s_sai = Number(s_sa);  

        const ni_id = idni;
        const network_f = network_feedback; 
        const s_ni = sni;
        const ni_idi = Number(ni_id);
        const s_nii = Number(s_ni);  

        const body = JSON.stringify({
            "jsonrpc": "2.0",
            "params": {
                  "employee": id,
                  "event": e_id,
                  "modul": m_id,
                  "penilai": penilai_idi,
                  "entitas": entitas_idi,
                  "assessment_line":[
                      {
                      "core_values_id": 2,
                      "name": "Selalu berupaya membangun & menjaga Partnership dengan senantiasa menjunjung tinggi Nilai-nilai Kesetaraan",
                      "star_rating_id": p_idi,
                      "feedback": partner_f,
                      "total_score": s_pi
                      },
                      {
                      "core_values_id": 3,
                      "name": "Senantiasa menghargai dan merawat Keberagaman dalam bingkai Persatuan Indonesia",
                      "star_rating_id": du_idi,
                      "feedback": diversity_f,
                      "total_score": s_dui
                      },
                      {
                      "core_values_id": 4,
                      "name": "Selalu membangun dan mempraktekan Kepemimpinan yang Berintegritas dengan menjunjung tinggi moralitas, kejujuran dan kesesuaian antara perkataan & perbuatan.",
                      "star_rating_id": li_idi,
                      "feedback": leader_f,
                      "total_score": s_lii
                      },
                      {
                      "core_values_id": 5,
                      "name": "Senantiasa berpikir & bersikap inovatif untuk menghasilkan solusi, produk atau layanan baru yang dapat memberikan nilai tambah bagi stakeholder.",
                      "star_rating_id": in_idi,
                      "feedback": innovation_f,
                      "total_score": s_ini
                      },
                      {
                      "core_values_id": 6,
                      "name": "Selalu mengutamakan keselamatan seluruh stakeholder serta mampu beradaptasi dengan cepat dan lincah atas dinamika perubahan yang terjadi.",
                      "star_rating_id": sa_idi,
                      "feedback": safety_f,
                      "total_score": s_sai
                      },
                      {
                      "core_values_id": 7,
                      "name": "Senantiasa membangun jejaring kerja yang luas dan beragam, serta menciptakan lingkungan kerja yang inklusif dengan memberikan kesempatan berpendapat & berkontribusi yang sama kepada karyawan dengan beragam suku, agama & ras tanpa adanya diskriminasi.",
                      "star_rating_id": ni_idi,
                      "feedback": network_f,
                      "total_score": s_nii
                      }
                  ]
            }
          });

        const config = {
          method: "post",
          url: "https://odoo-toca.ykbut.co.id/api/post",
          headers: {
            "Content-Type": "application/json",
          },
          data: body,
        };
        axios(config)
        .then(response => {
          console.log(response.data);
          console.log("Posted data:", response.data);
          Swal.fire({
            icon: "success",
            title: "DONE!!!",
            text: "Terimakasih sudah mengisi assessment!",
            confirmButtonColor: "#59c1bd",
            confirmButtonText: "Berikan Feedback"
          }).then(function() {
            // Panggil handleTriggerClick setelah Swal berhasil
            handleTriggerClick();
          });
        })
        .catch(error => {
          console.error('Error:', error);
        });
      }
    };
  };
  
  const handleTriggerClick = () => {
    // Check if the user has already given feedback
    if (localStorage.getItem('feedbackSubmitted')) {
      // If feedback has already been submitted, show a message and return
      Swal.fire({
        icon: 'info',
        title: 'Anda telah memberikan feedback sebelumnya',
        text: 'Terima kasih atas kontribusinya!',
        confirmButtonColor: '#59c1bd',
        confirmButtonText: 'OK',
      });
      history.push('/Homepage');
      return;
    }
  
    Swal.fire({
      title: 'Berikan penilaian untuk aplikasi ini!',
      html: `
        <div>
          <div class="emoji-options">
            <button type="button" class="emoji-option" data-emoji="1"><i class="far fa-smile"></i></button>
            <button type="button" class="emoji-option" data-emoji="2"><i class="far fa-meh"></i></button>
            <button type="button" class="emoji-option" data-emoji="3"><i class="far fa-frown"></i></button>
          </div>
          <textarea id="feedback" class="swal2-textarea" placeholder="Your Feedback..."></textarea>
        </div>`,
      confirmButtonColor: '#59c1bd',
      confirmButtonText: 'Apply',
    }).then((result) => {
      if (result.isConfirmed) {
        const feedback = document.getElementById('feedback').value;
  
        // Extract clicked button's emoji value
        const clickedButton = document.querySelector('.emoji-options button.active');
        const selectedEmoji = clickedButton ? clickedButton.dataset.emoji : null;
  
        // Handle feedback and emoji
        if (selectedEmoji) {
          // Use selectedEmoji for further processing
          console.log('User selected emoji:', selectedEmoji);
  
          const body = {
            jsonrpc: "2.0",
            params: {
              emoji: selectedEmoji,
              feedback: feedback,
            },
          };
  
          const config = {
            method: "post",
            url: "https://odoo-toca.ykbut.co.id/api/feedback_app",
            headers: {
              "Content-Type": "application/json",
            },
            data: body,
          };
  
          axios(config)
            .then(response => {
              console.log(response.data);
              Swal.fire({
                icon: 'success',
                title: 'Feedback Terkirim!',
                text: 'Terima kasih sudah mengisi feedback!',
                confirmButtonColor: '#59c1bd',
                confirmButtonText: 'OK',
              });
              history.push('/Homepage');
              // Set a flag in local storage to indicate that feedback has been submitted
              localStorage.setItem('feedbackSubmitted', 'true');
            })
            .catch(error => {
              console.error('Error:', error);
            });
        } else {
          console.log('User did not select an emoji.');
        }
  
        console.log('User feedback:', feedback);
      }
    });
  
    document.querySelectorAll('.emoji-options button.emoji-option').forEach((button) => {
      button.addEventListener('click', () => {
        // Remove active class from previously clicked button
        const previouslyActiveButton = document.querySelector('.emoji-options button.active');
        if (previouslyActiveButton) previouslyActiveButton.classList.remove('active');
  
        // Add active class to the clicked button
        button.classList.add('active');
      });
    });
  };  
  
  const handleBacktButton = () => {
    history.push('/AssessmentTools5');
  };

  return (
    <div className="card-quest1">
      <div className={["logo-peduli-asst"]}>
        <img className="logo-pi" src={peduli_logo} />
      </div>

      <div className="">
        <div className={["card-assessment"]}>
          {assessmentData.map((item, idx) => (
            <div className="div">
              <p key={idx} className={["txt-values"]}>{item.core}</p>
              <p key={idx} className={["txt-definisi"]}>{item.description}</p>
            </div>
          ))}
        </div>

        {assData.map((item, idx) => (
            <div key={idx} className="card-quest">
              <div className='option'>
                <input 
                id={`option-${idx}`} 
                type="radio" 
                name="field" 
                value={item}
                checked={network === item}
                onChange={handleOptionChange} 
                />
                <label key={idx} htmlFor={`option-${idx}`} className={["font-option"]}>{item}</label>
              </div>
            </div>
          ))}
        
          <div className="feedback">
            <Typography className='header-feedback'> Employees Feedback</Typography>
            <textarea 
            className="komentar"
            placeholder="Minimal 15 Karakter"
            value={network_feedback}
            onChange={handleFeedbackChange}
            ></textarea>
      </div>
        <div className="btn-page1">
          <button type="button" className="btn-feedback-form" onClick={handleBacktButton}>
            Back
          </button>
          <button type="button" className="btn-feedback-form" onClick={handleNextButton}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default AssessmentPage6;

import React from 'react'
import TabelAssessor from '../TabelAssessor'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './TotalAssesor.css'
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import ykbutBg from '..//..//assets//background.png'

const TotalAssessor = () => {
  const history = useHistory();

  const handleClick = () => {
    // Use history.push to redirect to another page
    history.push('/Dashboard');
  };

  return (
    <div>
      <Box component="main" sx={{ 
        flexGrow: 1, p: 1,
        backgroundImage: `url(${ykbutBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center', 
        }} 
        className='img-ykbut'>
      <div className="back-icon-assessor">
        <ArrowBackIcon onClick={handleClick}/>
      </div>
      <div className="wrapper-6">
        <div className="text-assessor">
          <h1 className='judul-assessor'>Total Assessor</h1>
        </div>
        <div className='tabel-assessor'>
          <TabelAssessor />
        </div>
      </div>
      </Box>
    </div>
  )
}

export default TotalAssessor

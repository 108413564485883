// const id = localStorage.getItem("user_id");
import React, { useEffect, useState } from "react";
import axios from "axios";

export const useGetDataMagazine = () => {
  const [getDataMagazine, setGetDataMagazine] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    const fetchDataMagazine = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/magazine`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(response.data);
        setGetDataMagazine(response.data.data)
        return response.data;
      } catch (error) {
        // console.error("Error fetching data:", error);
        setError(error.message || "Error fetching contract data");
      } finally {
        setLoading(false)
      }
    };
    fetchDataMagazine()
  }, []);

  return { getDataMagazine, loading, error };
};
import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Home, Assessment, Settings, AccountCircle } from "@mui/icons-material";

const Botnav = () => {
  const [value, setValue] = React.useState(0);
  const history = useHistory();

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      className="w-full max-w-md fixed bottom-0 left-0 right-0 mx-auto bg-white"
      sx={{ boxShadow: "0px -1px 5px rgba(0, 0, 0, 0.2)" }}
    >
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
      >
        <BottomNavigationAction
          label="Home"
          icon={<Home />}
          onClick={() => history.push("/Landingpage")}
        />
        <BottomNavigationAction
          label="Assessment"
          icon={<Assessment />}
          onClick={() => history.push("/TotalAssessmentNew")}
        />
        <BottomNavigationAction
          label="Settings"
          icon={<Settings />}
          onClick={() => history.push("/settingsPage")}
        />
        <BottomNavigationAction
          label="Profile"
          icon={<AccountCircle />}
          onClick={() => history.push("/Profile2")}
        />
      </BottomNavigation>
    </Box>
  );
};

export default Botnav;

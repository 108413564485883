import React, { useState } from "react";
import logo from "../../assets/logo.png";
import ykbutlogo from "../../assets/ykbutlogo.png";
import { Typography } from "@mui/material";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "../AuthContext/AuthContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Signin = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const history = useHistory();
  const { setIsAuthenticated } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = JSON.stringify({
      jsonrpc: "2.0",
      params: {
        db:  `${process.env.REACT_APP_DB}`,
        login: login,
        password: password,
        create: true,
      },
    });

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/session/authenticate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    };

    try {
      const response = await axios(config);
      // console.log("Response Data: ", response.data);

      if (response.data.result && !response.data.result.error_message) {
        const { user_id, user_name, employee_id, token } = response.data.result;
        // console.log("access", token);
        localStorage.setItem("user_id", user_id);
        localStorage.setItem("user_name", user_name);
        localStorage.setItem("employee_id", employee_id);
        localStorage.setItem("accessToken", token); // Save access token
        setIsAuthenticated(true);
        history.push("/Landingpage");
      } else {
        Swal.fire({
          icon: "error",
          title: "Login Gagal",
          text: response.data.result.error_message || "Email tidak terdaftar!",
          confirmButtonColor: "#59c1bd",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Login Gagal",
        text: "Password salah atau server tidak merespons!",
        confirmButtonColor: "#59c1bd",
      });
    }
  };

  const handleforgot = () => {
    // Redirect to forgot password URL
    window.location.href = "https://itsm.ykbut.co.id/scp";
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-full md:max-w-md p-6 bg-white rounded-lg shadow-lg mx-2">
        <div className="flex flex-col items-center">
          <div className="logo mb-4">
            <img className="logo-tocaa w-10 h-10" src={logo} alt="logo" />
          </div>
          <div className="judul mb-2 text-center">
            <Typography className="toca-head text-4xl font-bold">
              Tools of Culture Assessment
            </Typography>
            <div className="text-sm tulisan text-center">
              <Typography className="nrp-tulisan text-lg text-gray-700 font-semibold mt-[-20]">
                Masukan Email dan Password
              </Typography>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="form-field flex items-center mb-4">
            <input
              className="login-toca border border-gray-300 rounded-md p-2 w-full"
              type="text"
              name="login"
              id="login"
              placeholder="Email"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              required
            />
          </div>
          <div className="form-field flex items-center mb-4 relative">
            <input
              className="login-toca border border-gray-300 rounded-md p-2 w-full pr-10"
              type={showPassword ? "text" : "password"} // Toggle input type
              name="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <VisibilityOffIcon style={{ fontSize: 18 }} /> // Adjust icon size in pixels
              ) : (
                <VisibilityIcon style={{ fontSize: 18 }} /> // Adjust icon size in pixels
              )}
            </button>
          </div>
          <button
            className="btn mt-4 bg-[#009EC5] text-white py-2 px-4 rounded-md hover:bg-[#008BB3] w-full"
            type="submit"
          >
            Masuk
          </button>
        </form>

        <div className="flex justify-between items-center mt-4">
          <button
            onClick={handleforgot}
            className="hover:underline text-xs font-bold mt-4"
            style={{ color: "#009EC5" }} // Use your desired hex color code
          >
            Forgot Password
          </button>
        </div>

        <hr className="my-6 border-gray-300" />

        <div className="flex items-center justify-center mt-6">
          <p className="text-sm text-center mr-2">Powered by</p>
          <img className="w-15 h-10" src={ykbutlogo} alt="ykbut logo" />
        </div>
      </div>
    </div>
  );
};

export default Signin;

import React, { useState } from 'react';
import styled from 'styled-components';
import './TabsChart.css';
import Chart from '../Chart/Chart';
import Chart2 from '../Chart2/Chart2';

const Tab = styled.button`
  font-size: 14px;
  padding: 2% 13%;
  border-radius:2%;
  cursor: pointer;
  opacity: 0.6;
  background: #176B87;
  color: white;
  border: none;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid #55B3D2;
    opacity: 1;
  `}
`;

const ButtonGroup = styled.div`
  display: flex;
`;

const TabsChart = () => {
  const [active, setActive] = useState('Peduli Insani'); 

  const renderTabContent = () => {
    switch (active) {
      case 'Peduli Insani':
        return <Chart />;
      case 'Pancadaya':
        return <Chart2 />;
      default:
        return null;
    }
  };

  return (
    <div className='tab'>
      <ButtonGroup className='button-chart'>
        {['Peduli Insani', 'Pancadaya'].map((type) => (
          <Tab
            key={type}
            active={active === type}
            onClick={() => setActive(type)}
          >
            {type}
          </Tab>
        ))}
      </ButtonGroup>
      {renderTabContent()}
    </div>
  );
};

export default TabsChart;
import React from 'react'
import SideBar from '../SideBar'
import { useState } from 'react'
import logo from '../../assets/logo.png'
import './Login.css'



const Login = () => {
  
  return (
    <div className='background'>
      <SideBar/>
   
    </div>
  )
}

export default Login;
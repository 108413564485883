// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-outline{
    width: 530px;
    height: 210px;
    margin-top: 80px;
    border-color: #6f6df4;
    margin-left: 320px
    
}




/* CSS */
.btn-feedbackpage{
    
}



/* CSS */


.btn-feedbackpage{
    margin-top: 10px;
    background-color:#176B87;
    color: #fff;
    border:none; 
    border-radius:5px; 
    padding:15px;
    height:35px; 
    width: 100px;
    margin-left:420px;
}
`, "",{"version":3,"sources":["webpack://./src/components/FeedbackPage/FeedbackPage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,qBAAqB;IACrB;;AAEJ;;;;;AAKA,QAAQ;AACR;;AAEA;;;;AAIA,QAAQ;;;AAGR;IACI,gBAAgB;IAChB,wBAAwB;IACxB,WAAW;IACX,WAAW;IACX,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".form-outline{\n    width: 530px;\n    height: 210px;\n    margin-top: 80px;\n    border-color: #6f6df4;\n    margin-left: 320px\n    \n}\n\n\n\n\n/* CSS */\n.btn-feedbackpage{\n    \n}\n\n\n\n/* CSS */\n\n\n.btn-feedbackpage{\n    margin-top: 10px;\n    background-color:#176B87;\n    color: #fff;\n    border:none; \n    border-radius:5px; \n    padding:15px;\n    height:35px; \n    width: 100px;\n    margin-left:420px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

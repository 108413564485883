import React, { useState } from "react";
import logo from "../../assets/logo.png";
import Box from "@mui/material/Box";
import ykbutBg from "../../assets/background.png";
import "./Signin.css";
import { Typography } from "@mui/material";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "../AuthContext/AuthContext";
// import jwt from "jsonwebtoken";

const Signin = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const { setIsAuthenticated } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = JSON.stringify({
      jsonrpc: "2.0",
      params: {
        db: "CAT",
        login: login,
        password: password,
      },
    });

    const config = {
      method: "post",
      url: "https://odoo-toca.ykbut.co.id/session/authenticate",
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    };

    try {
      const response = await axios(config);
      console.log("Response Data: ", response.data);

      if (response.data.result && !response.data.result.error_message) {
        const { user_id, user_name, employee_id } = response.data.result;
        localStorage.setItem("user_id", user_id);
        localStorage.setItem("user_name", user_name);
        localStorage.setItem("employee_id", employee_id);
        setIsAuthenticated(true);
        history.push("/homepage");
       
        history.push("/homepage");
      } else {
        Swal.fire({
          icon: "error",
          title: "Login Gagal",
          text:
            response.data.result.error_message ||
            "Email tidak terdaftar!",
          confirmButtonColor: "#59c1bd",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Login Gagal",
        text: "Password salah atau server tidak merespons!",
        confirmButtonColor: "#59c1bd",
      });
    }
  };

  return (
    <div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundImage: `url(${ykbutBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className="img-ykbut"
      >
        <div className="wrapper">
          <div className="logo">
            <img className="logo-tocaa" src={logo} alt="logo" />
          </div>
          <div className="judul">
            <Typography className="toca-head">
              Tools of Culture Assessment
            </Typography>
          </div>
          <div className="tulisan">
            <Typography className="nrp-tulisan">
              Masukan Email dan Password
            </Typography>
          </div>
          <form className="p-3 mt-3" onSubmit={handleSubmit}>
            <div className="form-field d-flex align-items-center">
              <input
                className="login-toca"
                type="text"
                name="login"
                id="login"
                placeholder="Email"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                required
              />
            </div>
            <div className="form-field d-flex align-items-center">
              <input
                className="login-toca"
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button className="btn mt-3" type="submit">
              Masuk
            </button>
          </form>
        </div>
      </Box>
    </div>
  );
};

export default Signin;

import React from "react";
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";
import ykbutBg from "..//..//assets//background.png";
import "./Settings.css";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";

const Settings = () => {

  const history = useHistory();

  const handleClick = () => {
    // Use history.push to redirect to another page
    history.push("/HomePage");
  };

  const handleChangePassword = () => {
    Swal.fire({
      title: 'Ganti Password',
      html: `
      <input id="swal-input2" type="password" class="swal2-input" placeholder="Password baru">
      <input id="swal-input3" type="password" class="swal2-input" placeholder="Konfirmasi password">`,
      confirmButtonColor: '#59c1bd',
      confirmButtonText: 'Kirim',
    }).then((result) => {
      if (result.isConfirmed) {
        const userId = localStorage.getItem('user_id');
        const newPassword = document.getElementById('swal-input2').value;
        const confirmPassword = document.getElementById('swal-input3').value;

        if (newPassword === confirmPassword) {
          // Memanggil API post change password
          const body = JSON.stringify({
            jsonrpc: "2.0",
              params: {
                "new_password": newPassword,
                // "conf_password": confirmPassword,
              },
          });
    
          const config = {
            method: "post",
            url: `https://odoo-toca.ykbut.co.id/web/change_password/${userId}`,
            headers: {
              "Content-Type": "application/json",
            },
            data: body,
          };
    
          axios(config)
            .then(function (response) {
              if (response.data.result) {
                Swal.fire('Sukses!', 'Password berhasil diperbarui', 'success');
                history.push("/");
              }
            })
            .catch(function (error) {
              console.error('Error:', error);
              Swal.fire('Error', 'Terjadi kesalahan saat memperbarui password', 'error');
            });
          
        }else{
            Swal.fire('Error', 'Password dan konfirmasi password tidak cocok', 'error');
        }
      }
    });
  };      

  return (
    <div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            backgroundImage: `url(${ykbutBg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          className="img-ykbut2"
        >
          <div className="back-icon-set">
            <ArrowBackIcon onClick={handleClick} />
          </div>
          <div className="wrapper-3">
            <div className="tentang-text">
              <Typography className="tentang">About Us </Typography>
              <p className="description">
              ToCA (Tools of Culture Assessment) merupakan suatu aplikasi yang menyediakan beberapa tools untuk karyawan memberikan assessment atau feedback dan apresiasi kepada siapapun seperti superior, peer, subordinate, dan diri sendiri. Aplikasi ToCA hanya dapat di akses oleh seluruh user/karyawan back office yang ada di YKBUT Group. Kriteria penilaiannya pun sesuai dengan core values yang ada di setiap modul culture YKBUT
              </p>
            </div>
              <button type="button" className="btn" onClick={handleChangePassword}>
                Ganti Password
              </button>
              <hr />
              <a href="https://drive.google.com/file/d/15q0Kl5v0_OGt1S8dMka4efOBj7eJi3FL/view?usp=sharing" class="btn">
              Guidance
              </a>
          </div>
        </Box>
    </div>
  );
};

export default Settings;

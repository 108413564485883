import React from 'react'
import './FeedbackPage.css'


const FeedbackPage = () => {
  return (
    <div>
        <div class="form-outline">
        <label class="form-label" for="textAreaExample">Feedback</label>
        <textarea class="form-control" id="textAreaExample1" rows="4"></textarea>
        <button type='button' className='btn-feedbackpage'>Submit</button>
    </div>
    </div>
  )
}

export default FeedbackPage
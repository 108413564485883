// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.judul-feedback{
    font-family: 'Poppins';
    font-size: 25px;
    font-weight: bold !important;
    margin-bottom: 6%;
    color: black;
}

.tabel-feedback{
    margin-left: 6%;
    margin-right: 6%;
}

.text-feedback {
    margin-top: 2%;
    display: flex;
    justify-content: center;
    margin-left: 10%;
  }
  
.back-icon-feedback {
    float: left;
    margin-top: 2%;
    padding-left: 4%;
    font-size: 900;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/Feedback/Feedback.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,4BAA4B;IAC5B,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,gBAAgB;EAClB;;AAEF;IACI,WAAW;IACX,cAAc;IACd,gBAAgB;IAChB,cAAc;IACd,WAAW;AACf","sourcesContent":[".judul-feedback{\n    font-family: 'Poppins';\n    font-size: 25px;\n    font-weight: bold !important;\n    margin-bottom: 6%;\n    color: black;\n}\n\n.tabel-feedback{\n    margin-left: 6%;\n    margin-right: 6%;\n}\n\n.text-feedback {\n    margin-top: 2%;\n    display: flex;\n    justify-content: center;\n    margin-left: 10%;\n  }\n  \n.back-icon-feedback {\n    float: left;\n    margin-top: 2%;\n    padding-left: 4%;\n    font-size: 900;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from "react";
import axios from "axios";

const Tabelfeedback = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    axios
      .get(`https://odoo-toca.ykbut.co.id/feedback/${userId}`)
      .then((response) => {
        setFeedbackData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [userId]);

  return (
    <div className="flex justify-center items-center h-full">
      <table className="table-auto w-4/5">
        <thead>
          <tr className="bg-blue-900 text-white" style={{ backgroundColor: '#135D66' }}>
            <th className="px-4 py-2">Core Values</th>
            <th className="px-4 py-2">Date</th>
            <th className="px-4 py-2">Score</th>
            <th className="px-4 py-2">Feedback</th>
          </tr>
        </thead>
        <tbody>
          {feedbackData.map((row, index) => (
            <tr key={index} className={index % 2 === 0 ? "bg-gray-200" : "bg-white"}>
              <td className="border px-4 py-2">{row.culture}</td>
              <td className="border px-4 py-2">{row.date}</td>
              <td className="border px-4 py-2">{row.score}</td>
              <td className="border px-4 py-2">{row.feedback}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Tabelfeedback;

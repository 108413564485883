import { Box, CardMedia, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Botnav from "../Layouts/Botnav";
import { useGetDataMagazine } from "../../Query/useGetDataMagazine";

const DetailMagazine = ({ dataDummy }) => {
  const { getDataMagazine, loading, error } = useGetDataMagazine();
  const [dataMagazine, setDataMagazine] = useState([]);

  // COMPONENT FOR FETCH MAGAZINE
  useEffect(() => {
    setDataMagazine(getDataMagazine);
  }, [getDataMagazine]);

  // console.log("dataMagazine", dataMagazine);
  // COMPONENT FOR FETCH MAGAZINE

  const history = useHistory();

  const { id } = useParams();
  const magazine = dataMagazine.find((item) => item.id === parseInt(id));

  // console.log("id", id);
  // console.log("magazine", magazine);

  return (
    <div className="text-center mx-auto mt-5 pb-20 px-4 sm:px-6 lg:px-8">
      {loading ? (
        // Menampilkan Skeleton jika loading
        <div>
          {[...Array(7)].map((_, index) => (
            <Skeleton key={index} animation="wave" height={"80px"} />
          ))}
        </div>
      ) : !magazine ? (
        // Menampilkan pesan jika data tidak ditemukan
        <p>Data tidak ditemukan</p>
      ) : (
        // Menampilkan konten utama jika data tersedia
        <>
          <Box className="w-full max-w-md mx-auto relative mb-4">
            <ArrowBackIosIcon
              sx={{
                position: "absolute",
                left: 0,
                top: "50%",
                fontSize: "18px",
                transform: "translateY(-50%)",
                cursor: "pointer",
                ml: 2,
              }}
              onClick={() => history.goBack()}
            />
            <h2 className="text-lg font-bold mx-auto">{magazine.name}</h2>
          </Box>
          <Box className="w-full max-w-md mx-auto">
            <CardMedia
              component="img"
              src={`data:image/jpeg;base64,${magazine.image}`}
              alt="merdeka"
              sx={{ objectFit: "cover" }}
            />
          </Box>
          <Box className="w-full max-w-md mx-auto text-left">
            <p className="mb-2 mt-7 text-lg font-bold">Description:</p>
            <p className="text-lg text-justify mt-2 text-sm">{magazine.description}</p>
          </Box>
          <Box className="w-full max-w-md mx-auto text-left">
            <p className="mb-2 mt-7 text-lg font-bold">Tautan:</p>
            <p className="text-lg text-justify mt-2 text-sm">
              <a
                className="underline text-blue-500"
                href={magazine.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {magazine.link}
              </a>
            </p>
          </Box>
        </>
      )}
      <Botnav />
    </div>
  );
};

export default DetailMagazine;
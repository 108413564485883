import React, { useState, useEffect } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis
} from "recharts";

export default function App() {
  const [data, setData] = useState([]);
   
  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    
    if (!userId) {
      console.error("User ID is not defined in localStorage");
      return;
    }

    fetch(`https://odoo-toca.ykbut.co.id/get_personal/${userId}`)
      .then(response => response.json())
      .then(apiData => {
        setData([
          {
            subject: "Partnership in Equity",
            A: apiData.average_score_Pe,
            fullMark: 150
          },
          {
            subject: "Diversity & Unity",
            A: apiData.average_score_Du,
            fullMark: 150
          },
          {
            subject: "Leadership With Integrity",
            A: apiData.average_score_li,
            fullMark: 150
          },
          {
            subject: "Innovation",
            A: apiData.average_score_In,
            fullMark: 150
          },
          {
            subject: "Safety & Agility",
            A: apiData.average_score_Sa,
            fullMark: 150
          },
          {
            subject: "Networking & Inclusion",
            A: apiData.average_score_Ni,
            fullMark: 150
          }
        ]);
      })
      .catch(error => console.error("Error fetching data:", error));
  }, []);

  const styles = {
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center"
    },
    chart: {
      fontSize: "7px",
      padding: "0",
      margin: "0",
      display: "flex",
      justifyContent: "center",
      marginleft: "2%",
      marginTop: "-8%"
    },
  };
 
  return (
    <div style={styles.container}>
      <div  style={styles.chart}>
        <RadarChart
          cx="50%"
          cy="50%"
          outerRadius={59}
          width={600}
          height={200}
          data={data}
        >
          <PolarGrid />
          <PolarAngleAxis dataKey="subject"  />
          <PolarRadiusAxis ticks={[1, 2, 3, 4, 5]} domain={[0, 5]} fill="#fff" />
          <Radar
            name="Mike"
            dataKey="A"
            stroke="#018A46"
            fill="#22975B"
            fillOpacity={0.6}
          />
        </RadarChart>
      </div>
    </div>
  );
}
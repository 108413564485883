import React, {useState,useEffect}  from 'react'
import './Tabelfeedback.css'
import { Table } from 'react-bootstrap';
import axios from 'axios';


const Tabelfeedback = () => {
  const [feedbackData, setAssessmentData] = useState([]);
  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    axios
      .get(`https://odoo-toca.ykbut.co.id/feedback/${userId}`)
      .then((response) => {
        setAssessmentData(response.data);
        // console.log("Data :",response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [userId]);

    
  return (
    <div  className='center '>
      <Table  className='table' responsive="sm">
        <thead >
          <tr>
            <th>Core Values</th>
            <th>Date</th>
            <th>Score</th>
            <th>Feedback</th>
          </tr>
        </thead>
        <tbody>
          {feedbackData.map((row) =>(
            <tr key={row.culture}>
              <td> {row.culture} </td>
              <td> {row.date} </td>
              <td> {row.score} </td>
              <td> {row.feedback} </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default Tabelfeedback

import React from "react";
import { useHistory } from "react-router-dom";
import { Box, BottomNavigation, BottomNavigationAction, Card, CardMedia } from '@mui/material';
import { Home, Assessment, Settings, AccountCircle } from '@mui/icons-material';
import AddIcon from "@mui/icons-material/Add";
import { NavLink } from "react-router-dom";
import TabelAsses2 from "../TabelAssess2";
import total from "../../assets/total.png"; // Mengimpor gambar total.png

const TotalAssessment = () => {
  const history = useHistory();
  const [value, setValue] = React.useState(1); // Set value sesuai dengan tab saat ini
  const [showModal, setShowModal] = React.useState(false); // State untuk kontrol modal
  const currentQuestionIndex = localStorage.getItem("currentQuestionIndex");
  const triggerForBackToHistory = localStorage.getItem("processedQuestions")

    // PENENTUANNYA ADALAH DISIN, KENAPA? JIKA TIDAK ADA "currentQuestionIndex" MAKA AKAN MEMREDIRECT KE Assesstform
  // YANG DIMANA Assesstform ITU AKAN MEMBUAT TYPE ID UNTUK GET SOALASSESSMENTS
  // JIKA TIDAK ADA "currentQuestionIndex" MAKA AKAN MEMREDIRECT KE Assesstform
  const handleAddAssessmentClick = () => {
    // Cek apakah ada `currentQuestionIndex` di localStorage
    if (currentQuestionIndex || triggerForBackToHistory) {
      setShowModal(true); // Tampilkan modal jika ada
    } else {
      // Jika tidak ada, langsung navigasi ke halaman form assessment baru
      history.push('/Assesstform');
    }
  };

  const handleGoToLastQuestion = () => {
    // Navigasi ke halaman pertanyaan terakhir
    history.push('/PeduliInsaniQuestion');
    setShowModal(false)
  };

  const handleStartNewAssessment = () => {
    // Navigasi ke halaman form assessment baru
    localStorage.removeItem("currentQuestionIndex");
    localStorage.removeItem("shuffledQuestions");
    localStorage.removeItem("assessmentValues");
    localStorage.removeItem("processedQuestions");
    localStorage.removeItem("type");
    localStorage.removeItem("type_id");
    localStorage.removeItem("progressPositions");
    history.push('/Assesstform');
    setShowModal(false)
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center justify-center py-8 relative overflow-hidden">
      {/* Fixed Card with Image */}
      <Card 
        className="w-full max-w-md mb-8 fixed top-0 left-1/2 transform -translate-x-1/2 z-10"  
        sx={{
          maxWidth: { xs: '100%', sm: '450px' },  // Responsif berdasarkan ukuran layar
          height: { xs: 'auto', sm: '170px' },     // Atur tinggi secara responsif
        }}
      >
        <CardMedia
          component="img"
          image={total} 
          alt="Total Assessment"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',  // Agar gambar pas dan tidak terdistorsi
          }}
        />
      </Card>

      {/* Scrollable Content */}
      <Box
        className="flex flex-col items-center w-full max-w-md p-4 bg-white flex-grow"
        sx={{ overflowY: 'auto', paddingTop: '220px', paddingBottom: '72px' }} // Tambahkan paddingTop untuk memberi ruang bagi Card dan paddingBottom untuk BottomNavigation
      >
        <div className="w-full px-4">
          <div className="-mt-12">
            <TabelAsses2/>
          </div>
          <div className="flex justify-center mt-4">
            {/* <NavLink to={currentQuestionIndex? "/PeduliInsaniQuestion" : '/Assesstform'} className="w-full"> */}
            {/* <NavLink to="/Assesstform" className="w-full"> */}
              <button
                onClick={handleAddAssessmentClick}
                className="bg-blue-500 hover:bg-blue-700 w-full text-white font-bold py-2 px-4 rounded"
                style={{ maxWidth: '420px' }}
              >
                <AddIcon className="mr-2" />
                Add Assessment
              </button>
            {/* </NavLink> */}
          </div>
        </div>
      </Box>

      {/* Bottom Navigation */}
      <Box className="w-full max-w-md fixed bottom-0 left-0 right-0 mx-auto bg-white">
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
        >
          <BottomNavigationAction 
            label="Home" 
            icon={<Home />} 
            onClick={() => history.push('/Landingpage')} 
            sx={{ 
              color: value === 0 ? 'blue' : 'inherit', // Warna biru jika tab aktif
              '& .MuiBottomNavigationAction-icon': { color: value === 0 ? 'blue' : 'inherit' } // Warna biru jika tab aktif
            }} 
          />
          <BottomNavigationAction 
            label="Assessment" 
            icon={<Assessment />} 
            onClick={() => history.push('/TotalAssessmentNew')} 
            sx={{ 
              color: value === 1 ? 'blue' : 'inherit', // Warna biru jika tab aktif
              '& .MuiBottomNavigationAction-icon': { color: value === 1 ? 'blue' : 'inherit' } // Warna biru jika tab aktif
            }} 
          />
          <BottomNavigationAction 
            label="Settings" 
            icon={<Settings />} 
            onClick={() => history.push('/SettingsPage')} 
            sx={{ 
              color: value === 2 ? 'blue' : 'inherit', // Warna biru jika tab aktif
              '& .MuiBottomNavigationAction-icon': { color: value === 2 ? 'blue' : 'inherit' } // Warna biru jika tab aktif
            }} 
          />
          <BottomNavigationAction 
            label="Profile" 
            icon={<AccountCircle />} 
            onClick={() => history.push('/Profile2')} 
            sx={{ 
              color: value === 3 ? 'blue' : 'inherit', // Warna biru jika tab aktif
              '& .MuiBottomNavigationAction-icon': { color: value === 3 ? 'blue' : 'inherit' } // Warna biru jika tab aktif
            }} 
          />
        </BottomNavigation>
      </Box>

      {/* Modal */}
      {showModal && (
        <div onClick={() => setShowModal(false)} className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg mx-5">
            <h2 className="text-xl font-bold mb-4">Kembali ke Pertanyaan Terakhir?</h2>
            <p>Apakah kamu ingin melanjutkan dari pertanyaan terakhir yang belum selesai?</p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={handleStartNewAssessment}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-2 rounded"
              >
                Tidak
              </button>
              <button
                onClick={handleGoToLastQuestion}
                className="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-5 rounded"
              >
                Iya
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TotalAssessment;

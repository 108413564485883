import React, { useEffect, useState } from "react";
import axios from "axios";
import peduli_logo from "../../assets/peduliinsani_logo.png";
import "./AssessmentPage.css";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const AssessmentPage3 = () => {
  const history = useHistory();
  const [assessmentData, setAssessmentData] = useState([]);
  const [assData, setAssData] = useState([]);
  const [idli, setIdli] = useState(-1);
  const [leader, setLeader] = useState('');
  const [leader_feedback, setLeader_feedback] = useState('');
  const [sli, setSli] = useState(-1);
  const userId = localStorage.getItem('user_id'); 
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://odoo-toca.ykbut.co.id/get_assessment_def`
        );
        const data = response.data;

        setAssessmentData(data.assessment_data3);
        setAssData(data.ass_data3);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  const handleOptionChange = (event) => {
    const leaderValue = event.target.value;
    setLeader(leaderValue);

    //get array selection
    const selectedValue = event.target.value;
    const selectedIndex = assData.findIndex((item) => item === selectedValue);
    // console.log(`Selected index: ${selectedIndex}`);
    let idli;

    switch (selectedIndex) {
      case 0:
        idli = 21;
        break;
      case 1:
        idli = 22;
        break;
      case 2:
        idli = 23;
        break;
      case 3:
        idli = 24;
        break;
      case 4:
        idli = 25;
        break;
      default:
        idli = -1;
    }
    setIdli(idli);

    let sli;

    switch (idli) {
      case 21:
        sli = 1;
        break;
      case 22:
        sli = 2;
        break;
      case 23:
        sli = 3;
        break;
      case 24:
        sli = 4;
        break;
      case 25:
        sli = 5;
        break;
      default:
        sli = -1;
    }
    setSli(sli);
  };

  const handleFeedbackChange = (event) => {
    const feedbackValue = event.target.value;
    setLeader_feedback(feedbackValue);
  };
  
  const handleNextButton = () => {
    if (!leader) {
      Swal.fire({
        icon: "warning",
        title: "Pilihan harus dipilih",
        text: "Pastikan Anda telah memilih salah satu opsi sebelum melanjutkan",
        confirmButtonColor: "#59c1bd"
      });
    } else {
      localStorage.setItem('idli', idli);
      localStorage.setItem('leader_feedback', leader_feedback);
      localStorage.setItem('sli', sli);
  
      if (sli <= 3 && leader_feedback.trim().length < 15) {
        Swal.fire({
          icon: "warning",
          title: "Feedback harus diisi",
          text: "Pastikan anda sudah mengisi feedback dengan minimal 15 karakter untuk score 1-3",
          confirmButtonColor: "#59c1bd"
        });
      } else {
        history.push('/AssessmentTools4');
      }
    }
  };

  const handleBacktButton = () => {

    history.push('/AssessmentTools2');
  };

  return (
    <div className="card-quest1">
      <div className={["logo-peduli-asst"]}>
        <img className="logo-pi" src={peduli_logo} />
      </div>

      <div className="">
        <div className={["card-assessment"]}>
          {assessmentData.map((item, idx) => (
            <div className="div">
              <p key={idx} className={["txt-values"]}>{item.core}</p>
              <p key={idx} className={["txt-definisi"]}>{item.description}</p>
            </div>
          ))}
        </div>

        {assData.map((item, idx) => (
            <div key={idx} className="card-quest">
              <div className='option'>
                <input 
                id={`option-${idx}`} 
                type="radio" 
                name="field" 
                value={item}
                checked={leader === item}
                onChange={handleOptionChange} 
                />
                <label key={idx} htmlFor={`option-${idx}`} className={["font-option"]}>{item}</label>
              </div>
            </div>
          ))}
        
          <div className="feedback">
            <Typography className='header-feedback'> Employees Feedback</Typography>
            <textarea 
            className="komentar"
            placeholder="Minimal 15 Karakter"
            value={leader_feedback}
            onChange={handleFeedbackChange}
            ></textarea>
      </div>
        <div className="btn-page1">
          <button type="button" className="btn-feedback-form" onClick={handleBacktButton}>
            Back
          </button>
          <button type="button" className="btn-feedback-form" onClick={handleNextButton}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default AssessmentPage3;

import React from "react";
import { useHistory } from "react-router-dom";
import { Box, BottomNavigation, BottomNavigationAction, Card, CardMedia } from '@mui/material';
import { Home, Assessment, Settings, AccountCircle } from '@mui/icons-material';
import TabelAssessor2 from "../TabelAssessor2/TabelAssessor2";
import results1 from "../../assets/results1.jpg";

const TotalAssessment = () => {
  const history = useHistory();
  const [value, setValue] = React.useState(1); // Set initial value if needed

  const handleNavigation = (event, newValue) => {
    setValue(newValue);

    switch (newValue) {
      case 0:
        history.push('/Landingpage');
        break;
      case 1:
        history.push('/TotalAssessmentNew');
        break;
      case 2:
        history.push('/settingsPage');
        break;
      case 3:
        history.push('/Profile2');
        break;
      default:
        break;
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center justify-center py-8 overflow-auto">
      <Box
        className="flex flex-col items-center w-full max-w-md p-4 bg-white flex-grow"
        style={{ paddingBottom: "80px" }} // Add padding to avoid overlap with BottomNavigation
      >
        <Card 
          className="w-full max-w-md mb-8"
          style={{ maxWidth: '450px', height: '170px', position: 'fixed', top: 0 }}
        >
          <CardMedia
            component="img"
            image={results1} // Menggunakan gambar results1.jpg
            alt="Results"
            style={{ height: '100%', objectFit: 'cover' }} // Menyesuaikan gambar dengan ukuran kartu
          />
        </Card>

        <div className="w-full max-w-md px-4 mt-8" style={{ marginTop: '220px' }}>
          <div className="mt-8">
            <TabelAssessor2 />  
          </div>
        </div>
      </Box>

      <Box className="w-full max-w-md fixed bottom-0 left-0 right-0 mx-auto bg-white">
        <BottomNavigation
          value={value}
          onChange={handleNavigation}
          showLabels
        >
          <BottomNavigationAction
            label="Home"
            icon={<Home />}
            style={{ color: value === 0 ? 'blue' : 'inherit' }} // Change color when active
          />
          <BottomNavigationAction
            label="Assessment"
            icon={<Assessment />}
            style={{ color: value === 1 ? 'blue' : 'inherit' }} // Change color when active
          />
          <BottomNavigationAction
            label="Settings"
            icon={<Settings />}
            style={{ color: value === 2 ? 'blue' : 'inherit' }} // Change color when active
          />
          <BottomNavigationAction
            label="Profile"
            icon={<AccountCircle />}
            style={{ color: value === 3 ? 'blue' : 'inherit' }} // Change color when active
          />
        </BottomNavigation>
      </Box>
    </div>
  );
};

export default TotalAssessment;

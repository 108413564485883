import React from "react";
import { useHistory } from "react-router-dom";
import { Box, BottomNavigation, BottomNavigationAction, Typography } from '@mui/material';
import { Home, Assessment, Settings, AccountCircle } from '@mui/icons-material';
import AddIcon from "@mui/icons-material/Add";
import { NavLink } from "react-router-dom";
import TabelAssessment from "../TabelAssessment";
import TabelFeedback2 from "../TabelFeedback2/TabelFeedback2";

const TotalAssessment = () => {
  const history = useHistory();
  const [value, setValue] = React.useState(0);

  return (
    <div className="kosong">
      <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center py-8">
      <Box
        sx={{
          position: 'fixed',
          p: 2,
          boxShadow: 3,
          bgcolor: '#2F3A8F',
          maxWidth: '500px',
          width: '100%',
          marginTop: '-30px',
          height: '180px',
          top: '50px',  // Sesuaikan dengan tinggi navbar atau elemen lain di atasnya
          zIndex: 999,  // Pastikan z-index lebih tinggi agar selalu muncul di atas konten
        }}
      >
        <div className="totalassessment2">
          <Typography>Total Feedback</Typography>
        </div>
      </Box>

        <div className="max-w-md w-full px-4">
          <div className="tabel-assessment mb-4">
            <TabelFeedback2 />
          </div>

        
        </div>
      </div>

      <Box className="w-full max-w-md fixed bottom-0 left-0 right-0 mx-auto bg-white">
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
        >
          <BottomNavigationAction label="Home" icon={<Home />} onClick={() => history.push('/HomePage')} />
          <BottomNavigationAction label="Assessment" icon={<Assessment />} onClick={() => history.push('/TotalAssessment')} />
          <BottomNavigationAction label="Settings" icon={<Settings />} onClick={() => history.push('/settings')} />
          <BottomNavigationAction label="Profile" icon={<AccountCircle />} onClick={() => history.push('/profile')} />
        </BottomNavigation>
      </Box>
    </div>
  );
};

export default TotalAssessment;

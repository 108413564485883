import React,{useEffect,useState} from 'react'
import './TabelAssessor.css'
import { Table } from 'react-bootstrap';
import axios from 'axios';


const TabelAssessor = () => {
    
  const [assesorData, setAssesorData] = useState([]);
  const [totalScore, setTotalScore] = useState([]);

  const userId = localStorage.getItem('user_id');

  const [showAssessorDetails, setShowAssessorDetails] = useState([]);

  useEffect(() => {
    setShowAssessorDetails(new Array(Math.ceil(assesorData.length / 6)).fill(false));
  }, [assesorData]);

  const toggleAssessorData = (index) => {
    setShowAssessorDetails((prev) => {
      const updatedArray = [...prev];
      updatedArray[index] = !updatedArray[index];
      return updatedArray;
    });
  };

  useEffect(() => {
    axios
      .get(`https://odoo-toca.ykbut.co.id/get_assessor/${userId}`)
      .then((response) => {
        setAssesorData(response.data);
        const newTotalScore = Array(Math.ceil(response.data.length / 6)).fill(0);
        for (let i = 0; i < response.data.length; i++) {
          newTotalScore[Math.floor(i / 6)] += response.data[i].score;
        }
        setTotalScore(newTotalScore);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [userId]);


  return (
    <div className="center">
      <Table className="table-asses" responsive="sm">
        <thead>
          <tr>
            <th>Assessor </th>
            <th>Date</th>
            <th>Score </th>
          </tr>
        </thead>
        {[...Array(Math.ceil(assesorData.length / 6))].map((_, index) => (
          <tbody key={index}>
            {assesorData.slice(index * 6, (index + 1) * 6).some((data) => data) && (
              <tr style={{ textAlign: "center" }}>
                <td>Assessor {index + 1}</td>
                <td>{assesorData[index * 6 + 5]?.date || "-"}</td>
                <td>{totalScore[index]}</td>
              </tr>
            )}
          </tbody>
        ))}
        
      </Table>
    </div>
  );
};


export default TabelAssessor;

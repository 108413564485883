import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { NavLink } from "react-router-dom";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import SpeedIcon from "@mui/icons-material/Speed";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import logopi from "../../assets/logopi.png";
import StarIcon from "@mui/icons-material/Star";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import "./SideBar.css";
import ykbutBg from "..//..//assets//background.png";
import LogoutIcon from "@mui/icons-material/Logout";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Link } from "react-router-dom";
import axios from "axios";
import ChartProfile from "../Chart/ChartProfile";
import { Typography, Paper } from "@mui/material";
import { useHistory } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import Swal from "sweetalert2";
import ButtonSidebar from "../ButtonSidebar/ButtonSidebar";
import podcast4 from "../../assets/podcast4.jpg";
import podcast5 from "../../assets/podcast 5.jpg";
import podcast6 from "../../assets/podcast 6.jpg";
import podcast1 from "../../assets/podcast31.jpg";
import podcast2 from "../../assets/podcast17.jpg";
import podcast3 from "../../assets/podcat3.jpg";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const contentData = [
  {
    imgSrc: podcast1,
    altText: "Deskripsi Gambar 1",
    title: "PODCAST CORNER #6",
    description: "31 May 2024",
  },
  {
    imgSrc: podcast2,
    altText: "Deskripsi Gambar 2",
    title: "PODCAST CORNER #5",
    description: "17 may 2024",
  },
  {
    imgSrc: podcast3,
    altText: "Deskripsi Gambar 3",
    title: "PODCAST CORNER #4",
    description: "3 may 2024",
  },
];

const Item = ({ imgSrc, altText, title, description }) => (
  <Paper elevation={3} className="carousel-item">
    <img src={imgSrc} alt={altText} className="carousel-img" />
    <Typography variant="h6">{title}</Typography>
    <Typography>{description}</Typography>
  </Paper>
);

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [userName, setUserName] = useState(localStorage.getItem("user_name"));
  const [employeesData, setEmployeesData] = useState([]);
  // const [averageScore, setAverageScore] = useState(0);
  const [profileImageBinary, setProfileImageBinary] = useState(null);
  const userId = localStorage.getItem("user_id");
  // const data = localStorage.getItem('roundedAverageScore');
  // const entitas = localStorage.getItem('entitas');
  const history = useHistory();
  // console.log(data)
  const [changingPassword, setChangingPassword] = useState(false);
  const alertShown = localStorage.getItem(
    "password_change_alert_shown_" + userId
  );
  const [passwordPopupShown, setPasswordPopupShown] = useState(false);
  const passwordChanged = localStorage.getItem("password_changed");
  // const alertShown = localStorage.getItem('password_change_alert_shown');
  const isAlertShown = localStorage.getItem("change_password_alert_shown");
  const hasPassword = localStorage.getItem("has_password");
  const hasShownPasswordPopupKey = `has_shown_password_popup_${userId}`;
  const hasShownPasswordPopup = localStorage.getItem(
    "has_shown_password_popup"
  );
  // Setelah pengguna berhasil mengubah kata sandi

  useEffect(() => {
    const auth = localStorage.getItem("isAuthenticated");
    if (!auth) {
      history.push("/");
    } else {
      // Redirect to Dashboard or another authenticated route
      history.push("/Homepage");
    }

    if (userId) {
      axios
        .get(`https://odoo-toca.ykbut.co.id/get_data_user/${userId}`, {
          responseType: "json",
        })
        .then((response) => {
          const profileImageBinary =
            response.data.employee_data[0].profile_image_binary;
          setEmployeesData(response.data.employee_data);
          localStorage.setItem("profile_image_url", profileImageBinary);

          if (profileImageBinary) {
            setProfileImageBinary(profileImageBinary);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }

    // Menampilkan alert hanya jika pengguna tidak sedang mengganti kata sandi
    if (userId) {
      axios
        .get(`https://odoo-toca.ykbut.co.id/get_data_user/${userId}`, {
          responseType: "json",
        })
        .then((response) => {
          const profileImageBinary =
            response.data.employee_data[0].profile_image_binary;
          setEmployeesData(response.data.employee_data);
          localStorage.setItem("profile_image_url", profileImageBinary);

          if (profileImageBinary) {
            setProfileImageBinary(profileImageBinary);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    // Menampilkan alert hanya jika pengguna tidak sedang mengganti kata sandi
  }, [userId, changingPassword, passwordPopupShown]);
  // Cek apakah alert sudah ditampilkan sebelumnya untuk pengguna ini
  // Jika alert belum ditampilkan sebelumnya dan kondisi lain terpenuhi

  if (!localStorage.getItem(hasShownPasswordPopupKey)) {
    Swal.fire({
      title: "Ganti Password untuk keamanan akun anda",
      html: `
      <input id="swal-input2" type="password" class="swal2-input" placeholder="Password baru">
      <input id="swal-input3" type="password" class="swal2-input" placeholder="Konfirmasi password">`,
      confirmButtonColor: "#59c1bd",
      confirmButtonText: "Kirim",
      allowOutsideClick: false, // Mencegah klik di luar untuk menutup popup
      allowEscapeKey: false, // Mencegah menutup popup dengan tombol escape
      allowEnterKey: false, // Mencegah menutup popup dengan tombol enter
      preConfirm: () => {
        // Tambahkan validasi di sini
        const newPassword = document.getElementById("swal-input2").value;
        const confirmPassword = document.getElementById("swal-input3").value;

        if (!newPassword || !confirmPassword) {
          Swal.showValidationMessage(
            "Password dan konfirmasi password harus diisi"
          );
          return false;
        }

        if (newPassword !== confirmPassword) {
          Swal.showValidationMessage(
            "Password dan konfirmasi password tidak cocok"
          );
          return false;
        }

        return { newPassword: newPassword }; // Kembalikan data yang akan digunakan
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const newPassword = result.value.newPassword;

        // Memanggil API post change password
        const body = JSON.stringify({
          jsonrpc: "2.0",
          params: {
            new_password: newPassword,
          },
        });

        const config = {
          method: "post",
          url: `https://odoo-toca.ykbut.co.id/web/change_password/${userId}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: body,
        };

        axios(config)
          .then(function (response) {
            if (response.data.result) {
              Swal.fire("Sukses!", "Password berhasil diperbarui", "success");
              // Menandai bahwa popup sudah ditampilkan untuk user ini
              localStorage.setItem(hasShownPasswordPopupKey, true);
              // Menandai bahwa pengguna sudah memiliki password
              localStorage.setItem("has_password", true);
              history.push("/");
            }
          })
          .catch(function (error) {
            console.error("Error:", error);
            Swal.fire(
              "Error",
              "Terjadi kesalahan saat memperbarui password",
              "error"
            );
          });
      }
    });
  }

  const entitas = employeesData.map((employee) => {
    return employee.entitas;
  });

  // const roundedAverageScore = averageScore.toFixed(2);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const LogOut = async () => {
    setUserName(localStorage.getItem("user_name"));

    const result = await Swal.fire({
      icon: "warning",
      title: "Apakah anda ingin keluar?",
      // text: 'Password salah!',
      showCancelButton: true,
      confirmButtonColor: "#59c1bd",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    });

    if (result.isConfirmed) {
      localStorage.removeItem('user_name');
      history.push("/");
    } else {
    }
  };

  const handleStar = () => {
    history.push("/TotalAssessor");
  };

  const handleEntitas = () => {
    history.push("/Profile");
  };

  const handleAssessment = () => {
    history.push("/AssessmentForm");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{ background: "transparent", boxShadow: "none" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              ...(!open && { display: "none" }),
            }}
          >
            <ChevronLeftIcon className="color-icon" />
          </IconButton>
        </DrawerHeader>
        <img
          src={logopi}
          className={`logo-pi ${open ? "responsive-img" : ""}`}
        />
        <List className="text-sidebar">
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/Profile">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PersonIcon className="person-profile" />
                </ListItemIcon>
                <ListItemText
                  primary={"My Profile"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
            <Link to="/Dashboard">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SpeedIcon className="person-profile" />
                </ListItemIcon>
                <ListItemText
                  primary={"Dashboard"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>

            <Link to="/TotalAssessment">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ModeOutlinedIcon className="person-profile" />
                </ListItemIcon>
                <ListItemText
                  primary={"Assessment"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>

            <Link to="/Settings">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SettingsOutlinedIcon className="person-profile" />
                </ListItemIcon>
                <ListItemText
                  primary={"Settings"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
        <Link to="#" onClick={LogOut}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <LogoutIcon className="person-profile" />
            </ListItemIcon>
            <ListItemText
              primary={"Logout"}
              sx={{ opacity: open ? 1 : 0, color: "white" }}
            />
          </ListItemButton>
        </Link>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundImage: `url(${ykbutBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className="img-ykbut"
      >
        <DrawerHeader />
        <div className="text-selamat">
          <h1 className="selamat">Selamat Datang,</h1>
          <h1 className="toca">di Tools of Culture Assessment</h1>
          <h2 className="user"> {userName}</h2>
        </div>
        {profileImageBinary ? (
          <img
            src={`data:image/png;base64,${profileImageBinary}`}
            alt="Profil"
            className="img_sidebar"
            onError={(e) => console.error("Error loading profile image:", e)}
          />
        ) : (
          <p className="loading-text">Loading.....</p>
        )}
        <div className="card-container">
          <div className="content">
            <div>
              <ButtonSidebar />
            </div>
            <div className="wrapper-9 bg-white rounded-xl">
              <a href="/PersonalScore">
                <div className="chart pt-5">
                  <ChartProfile />
                </div>
              </a>
            </div>
            <div className="carousel w-100">
              <Carousel className="carousel-container">
                {contentData.map((item, index) => (
                  <Item key={index} {...item} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
}

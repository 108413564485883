// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.search {
    width: 100%;
    position: relative;
    display: flex;
    
  }
  
  .searchTerm {
    width: 350%;
    border: 3px solid #ffffff;
    border-right: none;
    padding: 5px;
    height: 28px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: #9DBFAF;
  }
  
  .searchTerm:focus{
    color: #00B4CC;
  }
  
  .searchButton {
    width: 70px;
    height: 28px;
    border: 1px solid #00B4CC;
    background: #00B4CC;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
  }
  
  /*Resize the wrap to see the search bar change!*/
  .wrap{
    margin-top: -190px;
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-right: 40px;
  }`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/SearchBar.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;;EAEf;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,0BAA0B;IAC1B,aAAa;IACb,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,0BAA0B;IAC1B,eAAe;IACf,eAAe;EACjB;;EAEA,gDAAgD;EAChD;IACE,kBAAkB;IAClB,UAAU;IACV,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,kBAAkB;EACpB","sourcesContent":["\n.search {\n    width: 100%;\n    position: relative;\n    display: flex;\n    \n  }\n  \n  .searchTerm {\n    width: 350%;\n    border: 3px solid #ffffff;\n    border-right: none;\n    padding: 5px;\n    height: 28px;\n    border-radius: 5px 0 0 5px;\n    outline: none;\n    color: #9DBFAF;\n  }\n  \n  .searchTerm:focus{\n    color: #00B4CC;\n  }\n  \n  .searchButton {\n    width: 70px;\n    height: 28px;\n    border: 1px solid #00B4CC;\n    background: #00B4CC;\n    text-align: center;\n    color: #fff;\n    border-radius: 0 5px 5px 0;\n    cursor: pointer;\n    font-size: 20px;\n  }\n  \n  /*Resize the wrap to see the search bar change!*/\n  .wrap{\n    margin-top: -190px;\n    width: 30%;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    margin-right: 40px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import peduliinsani from "../../assets/peduliinsani_logo.png";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import "./PancadayaPage.css";

const PancadayaPage = () => {
  const history = useHistory();

  const handleBackButton = () => {
    history.push("/PancadayaTools");
  };

  const handleNextButton = () => {
    history.push("/PancadayaQuest");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="flex items-center justify-center mb-8 mt-2">
        <img
          className="w-60 h-15"
          src={peduliinsani}
          alt="Peduli Insani Logo"
        />
      </div>

      <div className="w-full max-w-md mx-auto">
        <Card className="w-full max-w-md mx-auto">
          <CardContent className="p-6 bg-[#3468C0] text-white">
            <div className="core-value text-left text-lg font-semibold text-white mb-2">
              <p>Partnership In Equity</p>
            </div>
            <div className="text-left text-small mt-1">
            Selalu berupaya membangun & menjaga Partnership dengan senantiasa menjunjung tinggi Nilai-nilai Kesetaraan
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="w-full max-w-md mx-auto mt-80">
        <button
          variant="contained"
          className="bg-[#38419D] text-white w-full h-10 text-sm rounded-lg font-semibold" // Menggunakan lebar penuh
          onClick={handleNextButton}
        >
          Start Assessment
        </button>
      </div>
    </div>
  );
};

export default PancadayaPage;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import StarIcon from "@mui/icons-material/Star";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import './ButtonSidebar.css';


const ButtonSidebar = () => {

    const [employeesData, setEmployeesData] = useState([]);
    const [averageScore, setAverageScore] = useState(0);
    const [profileImageBinary, setProfileImageBinary] = useState(null);
    const userId = localStorage.getItem("user_id");
    // const data = localStorage.getItem('roundedAverageScore');
    // const entitas = localStorage.getItem('entitas');
    const history = useHistory();
    // console.log(data)

    useEffect(() => {
        if (userId) {
          axios
            .get(`https://odoo-toca.ykbut.co.id/get_data_user/${userId}`, {
              responseType: "json",
            })
            .then((response) => {
              const profileImageBinary =
                response.data.employee_data[0].profile_image_binary;
              setEmployeesData(response.data.employee_data);
              setAverageScore(response.data.average_score);
    
              localStorage.setItem("profile_image_url", profileImageBinary);
    
              if (profileImageBinary) {
                setProfileImageBinary(profileImageBinary);
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      }, [userId]);

    const entitas = employeesData.map((employee) => {
        return employee.entitas;
    });
    
    const roundedAverageScore = averageScore.toFixed(2);
    console.log(roundedAverageScore);

    const handleStar = () => {
        history.push("/TotalAssessor");
    };
    
    const handleEntitas = () => {
        history.push("/Profile");
    };
    
    const handleAssessment = () => {
        history.push("/AssessmentForm");
    };

  return (
    <div className="wrapper-5 ">
        <div className="content" style={{marginTop: '-5%'}}>
            <button type="button" className="btn-new-star" onClick={handleStar}>
                <StarIcon className="newstar" />
            </button>
            <button type="button" className="btn-new-entitas" onClick={handleEntitas}>
                <LocationCityIcon className="new-entitas" />
            </button>
            <button type="button" className="btn-new-assest" onClick={handleAssessment}>
                <BorderColorIcon className="new-assest" />
            </button>
        </div>
        <div className="total">
            <p className="total-star">{roundedAverageScore}</p>
            <div className="nama-entitas1">
                <p className="nama-entitas">{entitas}</p>
            </div>
            <div className="assesst-homepage1">
                <p className="assesst-homepage">Assesstments</p>
            </div>
        </div>
    </div>
  )
}

export default ButtonSidebar
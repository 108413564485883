import React from 'react'
import './SearchBar.css'

const SearchBar = () => {
  return (
    <div>
   <div class="wrap">
   <div class="search">
      <input type="text" class="searchTerm" placeholder="Search Menu"></input>
      <button type="submit" class="searchButton">
        <i class="fa fa-search"></i>
     </button>
   </div>
</div>
    </div>
  )
}

export default SearchBar
import React from 'react'
import Tabelfeedback from '../Tabelfeedback/Tabelfeedback'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './Feedback.css'
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import ykbutBg from '..//..//assets//background.png'

const Feedback = () => {
  const history = useHistory();

  const handleClick = () => {
    // Use history.push to redirect to another page
    history.push('/Dashboard');
  };

  return (
    <div>
      <Box component="main" sx={{ 
        flexGrow: 1, p: 1,
        backgroundImage: `url(${ykbutBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center', 
        }} 
        className='img-ykbut'>
      <div className="back-icon-feedback">
        <ArrowBackIcon onClick={handleClick}/>
      </div>
      <div className='wrapper-6'>
        <div className="text-feedback ">
          <h1 className='judul-feedback font-bold mr-7'>Feedback</h1>
        </div>
        <div className='tabel-feedback'>
          <Tabelfeedback />
        </div>
      </div>
      </Box>
    </div>
  )
}

export default Feedback

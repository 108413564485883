import React from 'react';
import './Chart2.css';

// const data = [
//   {
//     subject: "Partnership in Equity ",
//     A: 120,
//     B: 110,
//     fullMark: 150
//   },
//   {
//     subject: 
//     "diversity & Unity",
//     A: 98,
//     B: 130,
//     fullMark: 150
//   },
//   {
//     subject: "Leadership With Integrity",
//     A: 86,
//     B: 130,
//     fullMark: 150
//   },
//   {
//     subject: "Innovation",
//     A: 99,
//     B: 100,
//     fullMark: 150
//   },
//   {
//     subject: "Safety & Agility",
//     A: 85,
//     B: 90,
//     fullMark: 150
//   },
// ];

export default function Chart2() {

  // const styles = {
  //   container: {
  //     width: "100%",
  //     height: "100%",
  //     display: "flex",
  //     justifyContent: "center"
  //   },
  //   chart: {
  //     fontSize: "10px",
  //     padding: "0",
  //     margin: "0",
  //     display: "flex",
  //     justifyContent: "center",
  //     marginleft: "2%"
  //   },
  // };
  return (
    // <div style={styles.container}>
    // <div  style={styles.chart}>
    //   <RadarChart
    //     cx="50%"
    //     cy="50%"
    //     outerRadius={59}
    //     width={370}
    //     height={180}
    //     data={data}
    //   >
    //     <PolarGrid />
    //     <PolarAngleAxis dataKey="subject" />
    //     <PolarRadiusAxis />
    //     <Radar
    //       name="Mike"
    //       dataKey="A"
    //       stroke="#018A46"
    //       fill="#22975B"
    //       fillOpacity={0.6}
    //     />
    //   </RadarChart>
    // </div>
    // </div>
    <div className="">
      <div className='coming' style={{ textAlign: 'center', marginTop: '25%' }}>
        <h1 className='comingsoon' style={{ color: 'black', fontSize: '24px' }}>
          COMING SOON
        </h1>
      </div>
    </div>
  );
}


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.table{
    font-size: 0.9em;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px 5px 0 0;
    border-collapse: collapse;
    overflow: hidden;
}

.table thead tr {
    background: #176B87;
    color: #ffffff;
    font-weight: bold;
}

.table th,
.table td {
    padding: 12px 15px;
}

.table tbody tr {
    border-bottom: 1px solid #dddddd;
} 

.table tbody tr:nth-of-type(even) {
    background-color: #A5CCD9;
}

.table tbody tr:last-of-type {
    border-bottom: 2px solid #176B87;
}

.table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

@media (max-width: 576px) {
    .table {
      width: 100%;
    }
  
    .table th, .table td {
      padding: 8px; 
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Tabelfeedback/Tabelfeedback.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,wCAAwC;IACxC,0BAA0B;IAC1B,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,iBAAiB;AACrB;;AAEA;;IAEI,kBAAkB;AACtB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI;MACE,WAAW;IACb;;IAEA;MACE,YAAY;IACd;EACF","sourcesContent":[".center {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.table{\n    font-size: 0.9em;\n    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);\n    border-radius: 5px 5px 0 0;\n    border-collapse: collapse;\n    overflow: hidden;\n}\n\n.table thead tr {\n    background: #176B87;\n    color: #ffffff;\n    font-weight: bold;\n}\n\n.table th,\n.table td {\n    padding: 12px 15px;\n}\n\n.table tbody tr {\n    border-bottom: 1px solid #dddddd;\n} \n\n.table tbody tr:nth-of-type(even) {\n    background-color: #A5CCD9;\n}\n\n.table tbody tr:last-of-type {\n    border-bottom: 2px solid #176B87;\n}\n\n.table tbody tr.active-row {\n    font-weight: bold;\n    color: #009879;\n}\n\n@media (max-width: 576px) {\n    .table {\n      width: 100%;\n    }\n  \n    .table th, .table td {\n      padding: 8px; \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-icon {
    float: left; 
    padding-left: 4%; 
    color: hsl(0, 0%, 100%);
}

.wrapper-4 {
    width: 90%; /* Menggunakan persentase agar responsif */
    max-width: 380px; /* Menetapkan lebar maksimum */
    margin: 80px auto;
    height: 90%;
    padding: 20px 15px; /* Menyesuaikan padding */
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 10%;
    position: relative;
    overflow: auto !important;
}

/* Responsiveness for small screens (mobile) */
@media screen and (max-width: 767px) {
    .wrapper-4 {
        width: 100%; /* Menggunakan persentase agar responsif */
    }
}

/* Responsiveness for medium-sized screens (tablet) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .wrapper-4 {
        width: 70% !important; /* Menggunakan persentase agar responsif */
        margin-top: 20%; /* Sesuaikan sesuai kebutuhan */
        height: 60%;
    }
}

/* Default styles for larger screens (desktop) */
@media screen and (min-width: 1025px) {
    .wrapper-4 {
        width: 50%; /* Menggunakan persentase agar responsif */
        margin-top: 30px; /* Sesuaikan sesuai kebutuhan */
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/PersonalScore/PersonalScore.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,UAAU,EAAE,0CAA0C;IACtD,gBAAgB,EAAE,8BAA8B;IAChD,iBAAiB;IACjB,WAAW;IACX,kBAAkB,EAAE,yBAAyB;IAC7C,sBAAsB;IACtB,mBAAmB;IACnB,wCAAwC;IACxC,eAAe;IACf,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA,8CAA8C;AAC9C;IACI;QACI,WAAW,EAAE,0CAA0C;IAC3D;AACJ;;AAEA,qDAAqD;AACrD;IACI;QACI,qBAAqB,EAAE,0CAA0C;QACjE,eAAe,EAAE,+BAA+B;QAChD,WAAW;IACf;AACJ;;AAEA,gDAAgD;AAChD;IACI;QACI,UAAU,EAAE,0CAA0C;QACtD,gBAAgB,EAAE,+BAA+B;IACrD;AACJ","sourcesContent":[".back-icon {\n    float: left; \n    padding-left: 4%; \n    color: hsl(0, 0%, 100%);\n}\n\n.wrapper-4 {\n    width: 90%; /* Menggunakan persentase agar responsif */\n    max-width: 380px; /* Menetapkan lebar maksimum */\n    margin: 80px auto;\n    height: 90%;\n    padding: 20px 15px; /* Menyesuaikan padding */\n    background-color: #fff;\n    border-radius: 15px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    margin-top: 10%;\n    position: relative;\n    overflow: auto !important;\n}\n\n/* Responsiveness for small screens (mobile) */\n@media screen and (max-width: 767px) {\n    .wrapper-4 {\n        width: 100%; /* Menggunakan persentase agar responsif */\n    }\n}\n\n/* Responsiveness for medium-sized screens (tablet) */\n@media screen and (min-width: 768px) and (max-width: 1024px) {\n    .wrapper-4 {\n        width: 70% !important; /* Menggunakan persentase agar responsif */\n        margin-top: 20%; /* Sesuaikan sesuai kebutuhan */\n        height: 60%;\n    }\n}\n\n/* Default styles for larger screens (desktop) */\n@media screen and (min-width: 1025px) {\n    .wrapper-4 {\n        width: 50%; /* Menggunakan persentase agar responsif */\n        margin-top: 30px; /* Sesuaikan sesuai kebutuhan */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { Typography, BottomNavigation, BottomNavigationAction, Box, Tabs, Tab } from '@mui/material';
import { Home, Assessment, Settings, AccountCircle } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import Chart from "../../components/Chart/Chart";
import Development from "../../assets/development.png";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ padding: '16px', flexGrow: 1 }}
    >
      {value === index && (
        <Typography>{children}</Typography>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SimpleTabs = () => {
  const [value, setValue] = React.useState(0);
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNavigation = (event, newValue) => {
    setValue(newValue);

    switch (newValue) {
      case 0:
        history.push('/Landingpage');
        break;
      case 1:
        history.push('/TotalAssessment');
        break;
      case 2:
        history.push('/SettingsPage');
        break;
      case 3:
        history.push('/ProfileNew');
        break;
      default:
        break;
    }
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box style={{ flexGrow: 1, paddingBottom: '64px' }}>
        <Typography variant="h6" align="center" gutterBottom style={{ marginTop: '30px', fontWeight: "bold" }}>
          Personal Score Average
        </Typography>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" centered>
          <Tab label="Peduli Insani" {...a11yProps(0)} />
          <Tab label="Pancadaya" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Chart />
        </TabPanel>
        <TabPanel value={value} index={1}>
        <img
            className="w-[300px] h-[180px] mx-auto mt-5 mb-3"
            src={Development}
            alt="Development"
          />
          <Typography align="center" fontWeight={"bold"}>Coming soon</Typography>
        </TabPanel>
      </Box>
      <Box className="w-full max-w-md fixed bottom-0 left-0 right-0 mx-auto bg-white">
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          sx={{ boxShadow: "0px -1px 5px rgba(0, 0, 0, 0.2)" }}
        >
          <BottomNavigationAction label="Home" icon={<Home />} onClick={() => history.push('/Landingpage')} />
          <BottomNavigationAction label="Assessment" icon={<Assessment />} onClick={() => history.push('/TotalAssessmentNew')} />
          <BottomNavigationAction label="Settings" icon={<Settings />} onClick={() => history.push('/settingsPage')} />
          <BottomNavigationAction label="Profile" icon={<AccountCircle />} onClick={() => history.push('/Profile2')} />
        </BottomNavigation>
      </Box>
    </Box>
  );
};

export default SimpleTabs;

import React from "react";
import "./PersonalScore.css";
import TabsChart from "../TabsChart";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from '@mui/material/Box';
import ykbutBg from '..//..//assets//background.png'

const PersonalScore = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/Dashboard");
  };

  return (
    <div className="" >
      <Box component="main" sx={{ 
        flexGrow: 1, p: 1,
        backgroundImage: `url(${ykbutBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center', 
        }} 
        className='img-ykbut'>
      <div className="back-icon">
        <ArrowBackIcon onClick={handleClick}  className="h-20 w-30" style={{paddingLeft:"0%"}}/>  
      </div>
      <div className="wrapper-4">
        <div className="d-flex" style={{marginLeft:"-2%"}}>
          <h1 className="type-personal text-xl font-bold align-items-center text-center mt-8 mb-5" style={{marginLeft:"10%",marginRight:"10%",color:"black",marginTop:"3%",marginBottom:"10%"}}>Personal Score Average</h1>
        </div>
        <div className="tabs-chart mx-auto">
          <TabsChart/>
        </div>
      </div>
      </Box>
    </div>
  );
};

export default PersonalScore;
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo.png";
import LibraryBooksSharpIcon from "@mui/icons-material/LibraryBooksSharp";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import BookIcon from '@mui/icons-material/Book';
import { NavLink } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import PeopleIcon from "@mui/icons-material/People";
import "./Menu.css";
import Box from '@mui/material/Box';
import ykbutBg from '..//..//assets//background.png'
import { Typography } from "@mui/material";

const Menu = () => {
  const history = useHistory();

  const handleClick = () => {
    // Use history.push to redirect to another page
    history.push("/HomePage");
  };

  return (
    <div>
      <Box component="main" sx={{ 
      flexGrow: 1, p: 3,
      backgroundImage: `url(${ykbutBg})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center', 
      }} 
      className='img-ykbut1'>
    <div className="logo-menu" style={{ overflow: "auto", maxHeight: "100vh", padding: "20px" }}>
      <div>
        <div className="back-icon-dashboard">
          <ArrowBackIcon onClick={handleClick} />
        </div>
        <div className="flex items-center justify-center">
          <img className="mr-2 toca-logo-menu" src={logo} alt="Logo" />
          <p className="text-welcome">Dashboard</p>
        </div>

        <div style={{ marginTop: "2%" }}>
          <div style={{ width: "100%" }}>
            <div className="button-chart">
              <NavLink to="/PersonalScore">
                <button type="button" className="btn-chart">
                  <div className="button-content">
                    <ShowChartIcon className="grafik" />
                    <span className="chart-text">Personal Score Average</span>
                  </div>
                </button>
              </NavLink>
            </div>
          </div>

          <div style={{ width: "100%" }}>
            <div className="button-assessor">
              <NavLink to="/TotalAssessor">
                <button type="button" className="btn-assessor">
                  <div className="button-content">
                    <LibraryBooksSharpIcon className="grafik" />
                    <span className="chart-text">Total Assessor</span>
                  </div>
                </button>
              </NavLink>
            </div>
          </div>

          <div style={{ width: "100%" }}>
            <div className="button-feedback">
              <NavLink to="/Feedback">
                <button type="button" className="btn-feedback">
                  <div className="button-content">
                    <BookIcon className="grafik" />
                    <span className="chart-text">Feedback</span>
                  </div>
                </button>
              </NavLink>
            </div>
          </div>

          <div style={{ width: "100%" }}>
            <div className="button-mandatory">
              <NavLink to="/MandatoryAssessment">
                <button type="button" className="btn-mandatory">
                  <div className="button-content">
                    <EditIcon className="grafik" />
                    <span className="chart-text">Mandatory Assessment</span>
                  </div>
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Box>
  </div>
  );
};

export default Menu;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import "./AssessmentForm.css";
import Box from '@mui/material/Box';
import ykbutBg from '..//..//assets//background.png'

const AssessmentForm = () => {
  const history = useHistory();
  const [formData, setFormData] = useState([]);
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [nrp, setNrp] = useState('');
  const [entitas, setEntitas] = useState('');
  const [entitas_id, setEntitasId] = useState('');


  const [eData, seteData] = useState([]);
  const [event, setEvent] = useState('');
  const [event_id, setEvent_id] = useState('');

  const [mData, setMData] = useState([]);
  const [modul, setModul] = useState('');
  const [modul_id, setModul_id] = useState('');
  const user_id = localStorage.getItem()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://odoo-toca.ykbut.co.id/get_form');
        setFormData(response.data.employee);
        seteData(response.data.event);
        setMData(response.data.module);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);
  
  const handleClick = () => {
    history.push("/TotalAssessment");
  };

  const handleNextButton = () => {
    localStorage.setItem('event_id', event_id);
    localStorage.setItem('modul_id', modul_id);
    localStorage.setItem('id', id);
    localStorage.setItem('entitas',entitas);
    localStorage.setItem('entitas_id',entitas_id);

    history.push('/AssessmentTools1');
  };

   
  return (
    <div>
      <Box component="main" sx={{ 
        flexGrow: 1, p: 3,
        backgroundImage: `url(${ykbutBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center', 
        }} 
        className='img-ykbut'>
    <div className="bg-form">
      <div className="back-icon-form">
        <ArrowBackIcon onClick={handleClick} />
      </div>
      <div className='wrapper-2'>
      <form id="form" className="flex flex-col">
        <label for="name" className="nama-karyawan">Employee Name / Nrp</label>
        <input
          type="text"
          list="names"
          id="name"
          className="selected"
          value={name}
          onChange={(e) =>  {
            setName(e.target.value);
            setNrp('');
            setEntitas('');
          }}
          onBlur={() => {
            const selectedForm = formData.find((form) => form.name === name);
            if (selectedForm) {
              setNrp(selectedForm.nrp);
              setEntitas(selectedForm.entitas);
              setEntitasId(selectedForm.entitas_id);
              setId(selectedForm.id);
            }
          }}
          placeholder='Name'
      />
      <datalist id="names" className=''>
        <option value="" disabled hidden>Name</option>
        {formData.map((form, index) => {
            return (
              <option key={index} value={form.name}  className="option">
              {form.name} ({form.nrp})
              </option>
            );
        })}
      </datalist>
        <div>
          <label className="nama-event">Entitas</label>
          <input type="text" className="selected" value={entitas} readOnly  />
        </div>
        <label for="event" className="nama-event">Event Assessment</label>
        <select 
          className="selected" 
          value={event} 
          onChange={(e) => {
            setEvent(e.target.value);
            setEvent_id('')
          }}
          onBlur={() => {
            const selectedForm = eData.find((events) => events.name === event);
            if (selectedForm) {
              setEvent_id(selectedForm.id);
            }
          }}
          >
          <option value="" selected disabled hidden>Event Assessment</option>
          {eData.map((events, index) => {
              return (
                <option key={index} value={events.name} className='option'>
                  {events.name}
                </option>
              );
          })}
          </select>
          <label for="modul" className="nama-modul">Culture Module</label>
          <select 
          className="selected" 
          value={modul} 
          onChange={(e) => {
            setModul(e.target.value);
            setModul_id('')
          }}
          onBlur={() => {
            const selectedForm = mData.find((module) => module.name === modul);
            if (selectedForm) {
              setModul_id(selectedForm.id);
            }
          }}
          >
            <option value="" selected disabled hidden>Culture module</option>
              {mData.map((module, index) => {
                  return (
                    <option key={index} value={module.name}>
                      {module.name}
                    </option>
                  );
              })}
          </select>
          <button type="button" className="btn-next-form" onClick={handleNextButton}>Next</button>
      </form>
      </div>  
    </div>
    </Box>
    </div>
  );
};

export default AssessmentForm;

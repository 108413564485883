import React, { useEffect, useState } from 'react';
import axios from 'axios';
import peduli_logo from '../../assets/peduliinsani_logo.png'
import './AssessmentPage.css'
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Swal from "sweetalert2";

const AssessmentPage2 = () => {
  const history = useHistory();
  const [assessmentData, setAssessmentData] = useState([]);
  const [assData, setAssData] = useState([]);
  const [iddu, setIddu] = useState(-1);
  const [diversity, setDiversity] = useState('');
  const [diversity_feedback, setDiversity_feedback] = useState('');
  const [sdu, setSdu] = useState(-1);

  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://odoo-toca.ykbut.co.id/get_assessment_def`); 
        const data = response.data;

        setAssessmentData(data.assessment_data2); 
        setAssData(data.ass_data2);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userId]);

  const handleOptionChange = (event) => {
    const diversityValue = event.target.value;
    setDiversity(diversityValue);

    //get array selection
    const selectedValue = event.target.value;
    const selectedIndex = assData.findIndex((item) => item === selectedValue);
    // console.log(`Selected index: ${selectedIndex}`);
    let iddu;

    switch (selectedIndex) {
      case 0:
        iddu = 16;
        break;
      case 1:
        iddu = 17;
        break;
      case 2:
        iddu = 18;
        break;
      case 3:
        iddu = 19;
        break;
      case 4:
        iddu = 20;
        break;
      default:
        iddu = -1;
    }
    setIddu(iddu);

    let sdu;

    switch (iddu) {
      case 16:
        sdu = 1;
        break;
      case 17:
        sdu = 2;
        break;
      case 18:
        sdu = 3;
        break;
      case 19:
        sdu = 4;
        break;
      case 20:
        sdu = 5;
        break;
      default:
        sdu = -1;
    }
    setSdu(sdu);
  };

  const handleFeedbackChange = (event) => {
    const feedbackValue = event.target.value;
    setDiversity_feedback(feedbackValue);
  };

  const handleNextButton = () => {
    if (!diversity) {
      Swal.fire({
        icon: "warning",
        title: "Pilihan harus dipilih",
        text: "Pastikan Anda telah memilih salah satu opsi sebelum melanjutkan",
        confirmButtonColor: "#59c1bd"
      });
    } else {
      localStorage.setItem('iddu', iddu);
      localStorage.setItem('diversity_feedback', diversity_feedback);
      localStorage.setItem('sdu', sdu);
  
      if (sdu <= 3 && diversity_feedback.trim().length < 15) {
        Swal.fire({
          icon: "warning",
          title: "Feedback harus diisi",
          text: "Pastikan anda sudah mengisi feedback dengan minimal 15 karakter untuk score 1-3",
          confirmButtonColor: "#59c1bd"
        });
      } else {
        history.push('/AssessmentTools3');
      }
    }
  };

  const handleBacktButton = () => {

    history.push('/AssessmentTools');
  };

  return (
    <div className='card-quest1'>
        <div className={["logo-peduli-asst"]}>
            <img className='logo-pi' src={peduli_logo}/>
        </div>

        <div className=''>
        <div className={["card-assessment"]}>
          {assessmentData.map((item, index) => (
            <div key={index}>
              <p className={["txt-values"]}>{item.core}</p>
              <p className={["txt-definisi"]}>{item.description}</p>
            </div>
          ))}
        </div>

        {assData.map((item, idx) => (
          <div key={idx} className="card-quest">
            <div className='option'>
              <input 
              id={`option-${idx}`}
              type="radio" 
              name="field" 
              value={item}
              checked={diversity === item}
              onChange={handleOptionChange}
              />
              <label key={idx} htmlFor={`option-${idx}`} className={["font-option"]}>{item}</label>
            </div>
          </div>
        ))}
        
        <div className="feedback">
          <Typography className='header-feedback'> Employees Feedback</Typography>
          <textarea 
          className="komentar" 
          placeholder="Minimal 15 Karakter"
            value={diversity_feedback}
            onChange={handleFeedbackChange}
          ></textarea>
        </div>
        <div className="btn-page1">
          <button type="button" className="btn-feedback-form" onClick={handleBacktButton}>
            Back
          </button>
          <button type='button' className='btn-feedback-form' onClick={handleNextButton}>Next</button>
        </div>
      </div>
    </div>
  )
}

export default AssessmentPage2;

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, BottomNavigation, BottomNavigationAction, CardMedia } from "@mui/material";
import { Home, Assessment, Settings, AccountCircle } from "@mui/icons-material";
import pod2 from "../../assets/pod2.jpg";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const Description1 = () => {
  const history = useHistory();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="text-center mx-auto mt-5 pb-20 px-4 sm:px-6 lg:px-8">
     
      <Box className="w-full max-w-md mx-auto relative mb-4">
        {/* Back icon positioned at the far left */}
        <ArrowBackIosIcon
          sx={{
            position: 'absolute',
            left: 0,
            top: '50%',
            fontSize:'18px',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            ml: 2 // Margin-left for spacing from the edge
          }}
          onClick={() => history.goBack()} // Navigate back on click
        />
        {/* Center the heading */}
        <h2 className="text-xl font-bold mx-auto mt-7">
          Podcast #13
        </h2>
      </Box>
      <Box className="w-full max-w-md mx-auto mt-8">
        <CardMedia
          component="img"
          src={pod2}
          alt="nanda"
          sx={{ objectFit: "cover" }}
        />
      </Box>
      <Box className="w-full max-w-md mx-auto text-left">
        <p className="mb-2 mt-7 text-lg font-bold">Description:</p>
        <p className="text-lg text-justify mt-2 text-sm">
        Mengenal Lebih Dekat Sang Arsitek Data di Era Digital💻🔍

          <br />
          <br />
          Tau nggak siapa yang ada di balik semua data keren yang bikin dunia digital makin canggih? Yup, Data Engineer! Yuk, kenalan sama Data Engineer, si arsitek data yang bikin teknologi makin maju di era digital ini! 🔥💻

        </p>
      </Box>
      <Box className="w-full max-w-md mx-auto text-left">
        <p className="mb-2 mt-7 text-lg font-bold">Dilaksanakan pada:</p>
        <p className="text-lg text-justify mt-2 text-sm">
        Jumat, 30 Agustus 2024

          <br />
          Waktu: 14.00 - 16.00 WIB
        </p>
      </Box>
      <Box className="w-full max-w-md mx-auto text-left">
        <p className="mb-2 mt-7 text-lg font-bold">Tautan:</p>
        <p className="text-lg text-justify mt-2 text-sm">
          <a href="https://bit.ly/ZoomPodcastCornerYLC" target="_blank" rel="noopener noreferrer">
            https://bit.ly/ZoomPodcastCornerYLC
          </a>
        </p>
      </Box>
      <Box
        className="w-full max-w-md fixed bottom-0 left-0 right-0 mx-auto bg-white"
        sx={{ boxShadow: "0px -1px 5px rgba(0, 0, 0, 0.2)" }}
      >
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
        >
          <BottomNavigationAction
            label="Home"
            icon={<Home />}
            onClick={() => history.push("/Landingpage")}
          />
          <BottomNavigationAction
            label="Assessment"
            icon={<Assessment />}
            onClick={() => history.push("/TotalAssessmentNew")}
          />
          <BottomNavigationAction
            label="Settings"
            icon={<Settings />}
            onClick={() => history.push("/settingsPage")}
          />
          <BottomNavigationAction
            label="Profile"
            icon={<AccountCircle />}
            onClick={() => history.push("/Profile2")}
          />
        </BottomNavigation>
      </Box>
    </div>
  );
};

export default Description1;

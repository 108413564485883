import React, { useEffect, useState } from 'react';
import axios from 'axios';
import peduli_logo from '../../assets/peduliinsani_logo.png'
import './AssessmentPage.css'
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Swal from "sweetalert2";

const AssessmentPage = () => {
  const history = useHistory();
  const [assessmentData, setAssessmentData] = useState([]);
  const [assData, setAssData] = useState([]);
  const [idp, setIdp] = useState(-1);
  const [partner, setPartner] = useState('');
  const [partner_feedback, setPartner_feedback] = useState('');
  const [sp, setSp] = useState(-1);
  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    const fetchData = async () => { 
      try {
        const response = await axios.get(`https://odoo-toca.ykbut.co.id/get_assessment_def`); 
        const data = response.data;

        setAssessmentData(data.assessment_data1); 
        setAssData(data.ass_data1);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userId]);

  const handleOptionChange = (event) => {
    const partnerValue = event.target.value;
    setPartner(partnerValue);

    //get array selection
    const selectedValue = event.target.value;
    const selectedIndex = assData.findIndex((item) => item === selectedValue);
    // console.log(`Selected index: ${selectedIndex}`);
    let idp;

    switch (selectedIndex) {
      case 0:
        idp = 1;
        break;
      case 1:
        idp = 2;
        break;
      case 2:
        idp = 3;
        break;
      case 3:
        idp = 4;
        break;
      case 4:
        idp = 5;
        break;
      default:
        idp = -1;
    }
    setIdp(idp);
    
    let sp;

    switch (idp) {
      case 1:
        sp = 1;
        break;
      case 2:
        sp = 2;
        break;
      case 3:
        sp = 3;
        break;
      case 4:
        sp = 4;
        break;
      case 5:
        sp = 5;
        break;
      default:
        sp = -1;
    }
    setSp(sp);
  };

  const handleFeedbackChange = (event) => {
    const feedbackValue = event.target.value;
    setPartner_feedback(feedbackValue);
  };

  const handleNextButton = () => {
    if (!partner) {
      Swal.fire({
        icon: "warning",
        title: "Pilihan harus dipilih",
        text: "Pastikan Anda telah memilih salah satu opsi sebelum melanjutkan",
        confirmButtonColor: "#59c1bd"
      });
    } else {
      localStorage.setItem('idp', idp);
      localStorage.setItem('partner_feedback', partner_feedback);
      localStorage.setItem('sp', sp);
  
      if (sp <= 3 && partner_feedback.trim().length < 15) {
        Swal.fire({
          icon: "warning",
          title: "Feedback harus diisi",
          text: "Pastikan anda sudah mengisi feedback dengan minimal 15 karakter untuk score 1-3",
          confirmButtonColor: "#59c1bd"
        });
      } else {
        history.push('/AssessmentTools2');
      }
    }
  };  

  const handleBacktButton = () => {

    history.push('/AssessmentForm');
  };

  return (
    <div className='card-quest1'>
        <div className={["logo-peduli-asst"]}>
            <img className='logo-pi' src={peduli_logo}/>
        </div>

        <div className=''>
          <div className={["card-assessment"]}>
            {assessmentData.map((item, idx) => (
              <div key={idx}>
                <p className={["txt-values"]}>{item.core}</p>
                <p className={["txt-definisi"]}>{item.description}</p>
              </div>
            ))}
          </div>

          {assData.map((item, idx) => (
            <div key={idx} className="card-quest">
              <div className='option'>
                <input 
                id={`option-${idx}`} 
                type="radio" 
                name="field" 
                value={item}
                checked={partner === item}
                onChange={handleOptionChange} 
                />
                <label key={idx} htmlFor={`option-${idx}`} className={["font-option"]}>{item}</label>
              </div>
            </div>
          ))}
        
          <div className="feedback">
            <Typography className='header-feedback'> Employees Feedback</Typography>
            <textarea 
            className="komentar"
            placeholder="Minimal 15 Karakter"
            value={partner_feedback}
            onChange={handleFeedbackChange}
            ></textarea>
          </div>
          <div className="btn-page1">
            <button type="button" className="btn-feedback-form" onClick={handleBacktButton}>
              Back
            </button>
            <button type='button' className='btn-feedback-form' onClick={handleNextButton}>
              Next
            </button>
          </div>
      </div>
    </div>
  )
}

export default AssessmentPage;

import React from 'react';
import './SplashScreen.css';
import logopi from '../../assets/logopi.png'; 
import logoykbut from '../../assets/ykbutlogo.png';

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <img className='logo-splash' src={logopi} alt="" />
      <div className='text-splash'>
        <h1 className='text-from'>Powered by</h1>
      </div>
        <img className='wrap-logo' src={logoykbut} alt="" />
    </div>
  );
};

export default SplashScreen;
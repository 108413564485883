import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./components/Login/Login";
import SplashScreen from "./components/SplashScreen";
import Chart from "./components/Chart/Chart";
import ChartProfile from "./components/Chart/ChartProfile";
import Signin from "./components/Signin";
import Menu from "./components/Menu";
import SearchBar from "./components/SearchBar";
import SideBar from "./components/SideBar";
import Profile from "./components/Profile/Profile";
import TotalAssessor from "./components/TotalAssessor/TotalAssessor";
import PersonalScore from "./components/PersonalScore";
import Feedback from "./components/Feedback";
import TotalAssessment from "./components/TotalAssessment";
import AssessmentPage from "./components/AssessmentPage";
import AssessmentPage2 from "./components/AssessmentPage/AssessmentPage2";
import AssessmentPage3 from "./components/AssessmentPage/AssessmentPage3";
import AssessmentPage4 from "./components/AssessmentPage/AssessmentPage4";
import AssessmentPage5 from "./components/AssessmentPage/AssessmentPage5";
import AssessmentPage6 from "./components/AssessmentPage/AssessmentPage6";
import AssessmentForm from "./components/AssessmentForm";
import FeedbackPage from "./components/FeedbackPage";
import MandatoryAssessment from "./components/MandatoryAssesment";
import Settings from "./components/Settings";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import PartnershipQuestion1 from "./components/Partnership/PartnershiQuestion1";
import ProgressBar from "./components/ProgressBar";
import { AuthProvider } from "./components/AuthContext/AuthContext";
import Instruksi from "./components/Instruksi/Instruksi1";
import PancadayaPage from "./components/PancadayaPage/PancadayaPage";
import ButtomNavigation from "./components/BottomNavigation/BottomNavigation";
import BottomNavigation from "./components/BottomNavigation";
import LoginPage from "./components/LoginPage";
import Profile2 from "./components/Profile2";
import Homepage from "./components/Homepage";
import NewForm from "./components/Newform";
import PersonalScore2 from "./components/PersonalScore2";
import SettingsPage from "./components/SettingsPage";
import Assessor2 from "./components/Assessor2";
import password from "./components/password";
import Feedback2 from "./components/Feedback2/Feedback2";
import TotalAssessNew from "./components/TotalAssessNew";
import TabelAsses2 from "./components/TabelAssess2";
import ChangePassword from "./components/ChangePassword";
import Description from "./components/Description";
import Description2 from "./components/Description/Description2";
import Description3 from "./components/Description/Description3";
import DetailMagazine from "./components/DetailMagazine";

// const dataDummy = [
//   {
//     id: 1,
//     name: "Magazine 1",
//     image:
//       "https://raw.githubusercontent.com/react-icons/react-icons/master/react-icons.svg",
//     description: "“YKBUT MAGAZINE” ini, mengulas dan merangkum Highlight Event YKBUT dan Kanitra Group sepanjang tahun 2022. Tema yang kami usung pada Edisi Perdana ini adalah 'Road to 15th Anniversary Yayasan Karya Bakti United Tractors'. Majalah ini juga memuat artikel kegiatan yang bertemakan tentang ESG (Environtment, Social, and Governance), dan Corporate event.",
//     link: "https://www.npmjs.com/package/react-icons",
//   },
//   {
//     id: 2,
//     name: "Magazine 2",
//     image:
//       "https://raw.githubusercontent.com/react-icons/react-icons/master/react-icons.svg",
//     description: "Magazine 2 Description",
//     link: "https://www.npmjs.com/package/react-icons",
//   },
// ];

function App() {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem("hasVisitedBefore");
    // localStorage.removeItem('user_name');

    if (hasVisitedBefore) {
      setShowSplash(false);
    } else {
      const timeout = setTimeout(() => {
        setShowSplash(false);
        localStorage.setItem("hasVisitedBefore", "true");
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, []);

  const getAllLocalStorageItems = () => {
    const items = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      items[key] = localStorage.getItem(key);
    }
    return items;
  };

  // Contoh penggunaan
  const items = getAllLocalStorageItems();
  // console.log("items saya", items);

  return (
    <AuthProvider>
      <div>
        {showSplash ? (
          <SplashScreen />
        ) : (
          <Router>
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <PrivateRoute
                exact
                path="/PeduliInsaniQuestion"
                component={PartnershipQuestion1}
              />
              <PrivateRoute exact path="/Instruksi" component={Instruksi} />
              {/* <PrivateRoute exact path="/Description" component={Description} /> */}
              {/* <PrivateRoute
                exact
                path="/Description2"
                component={Description2}
              /> */}
              {/* <PrivateRoute
                exact
                path="/Description3"
                component={Description3}
              /> */}
              <PrivateRoute
                exact
                path="/TotalAssessmentNew"
                component={TotalAssessNew}
              />
              <PrivateRoute exact path="/Feedback2" component={Feedback2} />
              <PrivateRoute
                exact
                path="/Mandatory"
                component={MandatoryAssessment}
              />
              <PrivateRoute exact path="/Assessor2" component={Assessor2} />
              <PrivateRoute exact path="/Assesstform" component={NewForm} />
              <PrivateRoute
                exact
                path="/SettingsPage"
                component={SettingsPage}
              />
              <PrivateRoute
                exact
                path="/Changepassword"
                component={ChangePassword}
              />
              <PrivateRoute
                exact
                path="/PersonalScore2"
                component={PersonalScore2}
              />
              <PrivateRoute exact path="/Landingpage" component={Homepage} />
              <PrivateRoute path="/Profile2" component={Profile2} />
              <PrivateRoute exact path="/ProgressBar" component={ProgressBar} />
              <PrivateRoute
                exact
                path="/PartnershipInEquity"
                component={PancadayaPage}
              />
              <PrivateRoute path="/ProfileBaru" component={Profile2} />
              {/* <PrivateRoute
                exact
                path="/BottomNav"
                component={BottomNavigation}
              /> */}
              <PrivateRoute exact path="/LoginPage" component={LoginPage} />
              <PrivateRoute exact path="/Assessor2" component={Assessor2} />
              <PrivateRoute exact path="/Settings" component={Settings} />
              {/* route janggal */}
              <PrivateRoute
                exact
                path="/AssessmentForm"
                component={AssessmentForm}
              />
              {/* route janggal */}
              {/* <Route path="/Homepage" element={<PrivateRoute><SideBar /></PrivateRoute>} /> */}
              {/* <PrivateRoute path="/Homepage" component={SideBar} /> */}
              {/* <Route path="/Homepage" component={SideBar} /> */}
              <PrivateRoute
                exact
                path="/TotalAssessment"
                component={TotalAssessment}
              />
              {/* <PrivateRoute exact path="/Dashboard" component={Menu} />
              <PrivateRoute exact path="/Dashboard" component={Menu} /> */}
              {/* <PrivateRoute exact path="/Profile" component={Profile} /> */}
              <PrivateRoute
                exact
                path="/TotalAssessor"
                component={TotalAssessor}
              />
              <PrivateRoute
                exact
                path="/PersonalScore"
                component={PersonalScore}
              />
              <PrivateRoute exact path="/Feedback" component={Feedback} />
              <PrivateRoute
                exact
                path="/AssessmentTools1"
                component={AssessmentPage}
              />
              <PrivateRoute
                exact
                path="/AssessmentTools2"
                component={AssessmentPage2}
              />
              <PrivateRoute
                exact
                path="/AssessmentTools3"
                component={AssessmentPage3}
              />
              <PrivateRoute
                exact
                path="/AssessmentTools4"
                component={AssessmentPage4}
              />
              <PrivateRoute
                exact
                path="/AssessmentTools5"
                component={AssessmentPage5}
              />
              <PrivateRoute
                exact
                path="/AssessmentTools6"
                component={AssessmentPage6}
              />
              {/* <PrivateRoute
                exact
                path="/FeedbackPage"
                component={FeedbackPage}
              /> */}
              <PrivateRoute
                exact
                path="/MandatoryAssessment"
                component={MandatoryAssessment}
              />
              <PrivateRoute exact path="/Settings" component={Settings} />
              <PrivateRoute exact path="/Chart" component={Chart} />
              {/* <PrivateRoute
                exact
                path="/ChartProfile"
                component={ChartProfile}
              /> */}
              <PrivateRoute
                exact
                path="/magazine/:id"
                component={DetailMagazine}
                // dataDummy={dataDummy}
                // render={(props) => <DetailMagazine {...props} dataDummy={dataDummy} />}
                // render={(props) => (
                //   <DetailMagazine {...props} dataDummy={dataDummy} />
                // )}
              />
              <Redirect to="/" />
            </Switch>
          </Router>
        )}
      </div>
    </AuthProvider>
  );
}

export default App;

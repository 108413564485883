import React, { useState, useEffect, useRef } from 'react';
import { Typography, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import {
  Person as PersonIcon,
  Business as BusinessIcon,
  Smartphone as SmartphoneIcon,
  Email as EmailIcon,
  LocationOn as LocationOnIcon,
  Star as StarIcon,
  LocationCity as LocationCityIcon,
  ArrowBack as ArrowBackIcon,
  CameraAlt as CameraAltIcon,
  DownloadDone as DownloadDoneIcon,
} from '@mui/icons-material';
import axios from 'axios';
import Swal from "sweetalert2";
import { useAuth } from "../AuthContext/AuthContext";

const Profile = () => {
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const [employeesData, setEmployeesData] = useState([]);
  const [averageScore, setAverageScore] = useState(0);
  const userId = localStorage.getItem('user_id');
  const [profileImageBinary, setProfileImageBinary] = useState(null);
  const [fileInput, setFileInput] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/Login');
    }

    if (userId) {
      axios
        .get(`https://odoo-toca.ykbut.co.id/get_data_user/${userId}`, { responseType: 'json' })
        .then((response) => {
          const profileImageBinary = response.data.employee_data[0].profile_image_binary;
          setEmployeesData(response.data.employee_data);
          setAverageScore(response.data.average_score);

          if (profileImageBinary) {
            setProfileImageBinary(profileImageBinary);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [userId, isAuthenticated, history]);

  const entitas = employeesData.map((employee) => {
    return employee.entitas;
  });
  localStorage.setItem('entitas', entitas);

  const handleClick = () => {
    history.push('/Homepage');
  };

  const roundedAverageScore = averageScore.toFixed(2);
  localStorage.setItem('roundedAverageScore', roundedAverageScore);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const previewImageUrl = URL.createObjectURL(selectedFile);
      setPreviewImage(previewImageUrl);
      setFileInput(selectedFile);
    }
  };

  const handleUpdateProfile = () => {
    if (fileInput) {
      const formData = new FormData();
      formData.append('profile_image', fileInput);

      axios
        .post(`https://odoo-toca.ykbut.co.id/update_profile_image/${userId}`, formData)
        .then((response) => {
          if (response.data.error) {
            console.error('Error updating profile image:', response.data.error);
          } else {
            Swal.fire({
              icon: "success",
              title: "Upload foto berhasil",
              confirmButtonColor: "#59c1bd"
            });
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error('Error updating profile image:', error);
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Tidak ada file",
        text: "Silahkan pilih file!",
        confirmButtonColor: "#59c1bd"
      });
    }
  };

  const handleProfileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center py-8">
      <div className="w-full max-w-2xl bg-white rounded-lg shadow-md p-6 mx-6 h-65" > {/* Tambahkan mx-6 di sini */}
        <div className="flex justify-between items-center mb-6">
          <IconButton onClick={handleClick}>
            <ArrowBackIcon className="text-gray-600" />
          </IconButton>
          <Typography variant="h5" className="font-bold">Profile</Typography>
          <IconButton onClick={handleUpdateProfile}>
            <DownloadDoneIcon className="text-gray-600" />
          </IconButton>
        </div>
        <div className="flex justify-center mb-6">
          {previewImage ? (
            <img src={previewImage} alt="Preview" className="rounded-full w-20 h-20 object-cover" />
          ) : profileImageBinary ? (
            <img
              src={`data:image/png;base64,${profileImageBinary}`}
              alt="Profil"
              className="rounded-full w-20 h-20 object-cover" // Mengubah w-32 dan h-32 menjadi w-24 dan h-24
              onError={(e) => console.error('Error loading profile image:', e)}
            />
          ) : (
            <p className="text-gray-500">Loading...</p>
          )}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </div>  
        {/* <div className="flex justify-center mb-6">
          <IconButton onClick={handleProfileClick}>
            <CameraAltIcon className="text-gray-600" />
          </IconButton>
        </div> */}
        {employeesData.map((employee, index) => (
          <div key={index} className="text-center mb-6">
            <Typography variant="h7" className="font-bold">{employee.name}</Typography>
            <Typography variant="subtitle1" className="text-gray-600">{employee.position}</Typography>
            <div className="flex justify-center items-center mt-4">
              <StarIcon className="text-yellow-500" />
              <Typography variant="h7" className="font-bold ">{roundedAverageScore}</Typography>
            </div>
          </div>
        ))}
      </div>
      {employeesData.map((employee, index) => (
        <div key={index} className="w-full max-w-2xl bg-white rounded-lg shadow-md p-6 mt-6 mx-6"> {/* Tambahkan mx-6 di sini */}
          <Typography variant="h6" className="font-bold mb-4">Informasi Pribadi</Typography>
          <div className="flex items-center mb-4">
            <PersonIcon className="text-gray-600" />
            <div className="ml-4">
              <Typography className="font-semibold">NRP</Typography>
              <Typography className="text-gray-600">{employee.nrp}</Typography>
            </div>
          </div>
          <div className="flex items-center mb-4">
            <LocationCityIcon className="text-gray-600" />
            <div className="ml-4">
              <Typography className="font-semibold">Entitas</Typography>
              <Typography className="text-gray-600">{employee.entitas}</Typography>
            </div>
          </div>
          <div className="flex items-center mb-4">
            <SmartphoneIcon className="text-gray-600" />
            <div className="ml-4">
              <Typography className="font-semibold">Work Mobile</Typography>
              <Typography className="text-gray-600">{employee.work_phone}</Typography>
            </div>
          </div>
          <div className="flex items-center mb-4">
            <EmailIcon className="text-gray-600" />
            <div className="ml-4">
              <Typography className="font-semibold">Email Work</Typography>
              <Typography className="text-gray-600">{employee.work_email}</Typography>
            </div>
          </div>
          <div className="flex items-center">
            <LocationOnIcon className="text-gray-600" />
            <div className="ml-4">
              <Typography className="font-semibold">Work Location</Typography>
              <Typography className="text-gray-600">{employee.work_location}</Typography>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Profile;

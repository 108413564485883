import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { styled } from "@mui/system";
import logo from "../../assets/logo.png";
import Swal from "sweetalert2";

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "1rem",
  width: "35%",
  maxWidth: "900px",
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "0 1rem",
  },
}));

const StyledDescription = styled(Typography)({
  marginTop: "1rem",
  fontSize: "1.125rem",
  textAlign: "left",
});

const StyledList = styled("ol")({
  listStyleType: "decimal",
  paddingLeft: "1rem",
  marginTop: "1rem",
  textAlign: "left",
  width: "100%",
});

const ChangePassword = () => {
  const history = useHistory();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [hasShownPasswordPopup, setHasShownPasswordPopup] = useState(false);
  const [isForceChange, setIsForceChange] = useState(false);
  // const [isConnect, setIsConnect] = useState(localStorage.getItem("is_connect"));
  const hasShownPasswordPopupKey = "hasShownPasswordPopup";
  const isConnect = localStorage.getItem("is_connect");  

  // console.log("location path: ", history.location.pathname);

  useEffect(() => {
    // const hasShownPasswordPopup = localStorage.getItem(
    //   hasShownPasswordPopupKey
    // );

    // Jika belum pernah mengganti password, set mode wajib ubah password
    if (isConnect === "never") {
      setIsForceChange(true);
      
      // Mencegah user keluar dari halaman saat mode wajib ubah password
      const handleBackNavigation = (e) => {
        e.preventDefault();
        if (isForceChange) {
          Swal.fire({
            title: "Upss, Anda belum mengganti password",
            text: "Anda wajib mengganti password sebelum melanjutkan.",
            icon: "warning",
          });
        } else {
          history.go(-1); // Jika bukan mode wajib, izinkan kembali
        }
      };
  
      window.onpopstate = handleBackNavigation;
  
      return () => {
        window.onpopstate = null; // Bersihkan listener saat komponen unmount
      };

    }
  }, [isConnect]);

  // useEffect(() => {
  //   if (!localStorage.getItem(hasShownPasswordPopupKey)) {
  //     setHasShownPasswordPopup(true);
  //   }
  // }, []);

  const handleChangePassword = async (event) => {
    event.preventDefault(); // Mencegah halaman refresh

    if (newPassword !== confirmPassword) {
      setError("Password and confirmation password do not match.");
      return;
    }

    if (!newPassword || !confirmPassword) {
      setError("Password and confirmation password must be filled.");
      return;
    }

    setError(""); // Clear any existing errors

    const result = await Swal.fire({
      title: "Anda Yakin?",
      text: "Apakah anda yakin untuk mengganti password?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563EB",
      cancelButtonColor: "#d33",
      confirmButtonText: "iya, ubah",
    });

    if (result.isConfirmed) {
      const accessToken = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("user_id");

      const body = JSON.stringify({
        jsonrpc: "2.0",
        params: {
          new_password: newPassword,
        },
      });

      const url = `${process.env.REACT_APP_API_URL}/web/change_password/${userId}`;

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: body,
        });

        const data = await response.json();

        if (response.ok && data.result) {
          Swal.fire("Sukses!", "Password anda berhasil diperbarui.", "success");

          // Tandai bahwa password telah diubah
          localStorage.setItem(hasShownPasswordPopupKey, true);
          setIsForceChange(false); // Nonaktifkan mode wajib

          localStorage.setItem("has_password", true);
          history.push("/");
        } else {
          throw new Error(data.error || "An error occurred");
        }
      } catch (error) {
        console.error("Error:", error);
        Swal.fire(
          "Error",
          "An error occurred while changing the password.",
          "error"
        );
      }
    } else {
      Swal.fire("Cancelled", "Password gagal diubah.", "info");
    }
  };

  return (
    <div className="komponen-instruksi">
      <StyledContainer>
        <div className="flex items-center justify-start w-full">
          {!isForceChange && (
            <IconButton
              onClick={() => history.push("/Landingpage")}
              aria-label="back"
              sx={{mr: 2, mt: 2, padding: 0}}
            >
              <ArrowBack />
            </IconButton>
          )}
          <img className="mr-2 toca-logo-menu" src={logo} alt="Logo" />
          <p className="text-xl mt-8 ml-30">Change Password</p>{" "}
          {/* Adjust margin for text alignment */}
        </div>

        <StyledDescription variant="h7" sx={{ textAlign: "left" }}>
          Silahkan masukan password baru anda dibawah ini.
        </StyledDescription>
        <StyledList>
          <form onSubmit={handleChangePassword}>
            <div className="form-change">
              <TextField
                label="New Password"
                type="password"
                fullWidth
                margin="normal"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <TextField
                label="Confirm New Password"
                type="password"
                fullWidth
                margin="normal"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {error && <Typography color="error">{error}</Typography>}
            </div>
            <div className="button-instruksi flex justify-center mt-10 w-full">
              <Button
                sx={{
                  backgroundColor: "#2563EB",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1D4ED8",
                  },
                }}
                className="w-full text-white font-bold py-2 px-4 rounded"
                type="submit"
                // onClick={handleChangePassword}
              >
                Change Password
              </Button>
            </div>
          </form>
        </StyledList>
      </StyledContainer>
    </div>
  );
};

export default ChangePassword;
